import React from "react";
import { Input } from "antd";
import SimpleMap from './gm';
import axios from 'axios'

export default class SimpleMapWrapper extends React.Component {
  
  state = {
    lat: 59.955413,
    lng: 30.337844
  };
  // onClick = ({x, y, lat, lng, event}) => this.setState({lat, lng})
  // onClick = ({x, y, lat, lng, event}) => console.log({lat, lng})
  onSearch = search => {
    // console.log(search);
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyCibgSv-9Bnj-0nXMmEZTY_DlKal5jWeJk&input=${search}&inputtype=textquery&fields=formatted_address,name,geometry`;
    axios.post(url).then((res)=> console.log(res.data))
  };

  render() {
    return (
      <React.Fragment>
        <Input.Search
          placeholder="input search text"
          onChange={this.onSearch}
          style={{ width: 200, marginBottom: "2rem" }}
        />
        <SimpleMap
          style={{
            marginTop: "2rem"
          }}
        />
      </React.Fragment>
    );
  }
}
