import React, { Component } from "react";
import Header from "./header";
import {Button, Row, Col, Card} from "antd";
import { INSERT_SUBMISSIONS } from "./graphql";
import { API_ROOT } from "./util";
import axios from "axios";
import { WrappedNormalLoginForm } from "./addEraForm";

export default class AddEra extends Component {
  state = {
    loading: false,
    era: []
  };

  onAddEra = values => {
    console.log(this.formRef);
    this.formRef.props.form.setFields({
      upload: {
        value: this.formRef.state.fileListUpload[0],
        errors: this.formRef.state.fileListUpload.length ? undefined : "please upload image"
      }
    });
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let mutation = INSERT_SUBMISSIONS;
        mutation.variables = {
          objects: {
            description: values.description,
            hero_image: this.formRef.state.fileListUpload[0],
            name: values.era,
            order: 0,
            updated_at: new Date().toISOString()
          }
        };

        axios
          .post(API_ROOT, mutation)
          .then(res => {
            console.log("onAddEra", res.data);

            this.setState({
              loading: false
            });
            this.props.history.goBack();
          })
          .catch(err => {
            console.error(err);
          });
      }
    });
  };
  handleSubmit = e => {
    e.preventDefault();
  };
  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
  }
  render() {
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              Era
            </Col>
            <Col
              span={8}
              style={{
                textAlign: "right"
              }}
            >
              <Button
                type="danger"
                style={{
                  marginRight: "1rem"
                }}
                onClick={() => this.props.history.goBack()}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={this.onAddEra}>
                Add Era
              </Button>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Card>
                <Row type="flex" justify="center">
                  <Col span={12}>
                    <WrappedNormalLoginForm
                      onAddEra={this.onAddEra}
                      wrappedComponentRef={inst => (this.formRef = inst)}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
