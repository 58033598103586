import React from "react";
import { Row, Typography } from "antd";
const { Title, Paragraph, Text } = Typography;
export const RenderMicro = props => {
  return (
    <React.Fragment>
      {props.data.map((m, i) => {
        return (
          <React.Fragment>
            <Row style={{ marginBottom: "0.5rem" }}>
              <Title level={4}>
                Micro Detail {props.index ? props.index + 1 : i + 1}
              </Title>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Text type="secondary">Detail Name</Text>
              <Paragraph>{m.name}</Paragraph>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Text type="secondary">Hero image</Text> <br />
              <img src={m.hero_image} alt="hero_img" height="182px" width="182" style={{ objectFit: 'cover' }} />
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Text type="secondary">Video Link</Text>
              <Paragraph>
                <a href={m.video_url} target="blank">{m.video_url}</a>
              </Paragraph>
            </Row>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
