export const FETCH_ALL_ERA = {
  query: `
		query era( $order: [era_order_by!]){
      era (order_by: $order){
        id
        description
        name
        order
        updated_at
        monuments_aggregate (where: {is_active: {_eq: true}}){
      aggregate {
        count
      }
    }
      }
		}
  	`
};
export const INSERT_SUBMISSIONS = {
  query: `
		mutation insert_era($objects: [era_insert_input!]!){
			insert_era(objects: $objects){
        affected_rows
			}
		}
	`
};

export const UPDATE_SUBMISSIONS = {
  query: `
		mutation update_era($id: Int!, $desc: String!, $hero: String!, $name: String!, $order: Int!, $updated_at: timestamptz){
			update_era(where: {id: {_eq: $id}}, _set: {description: $desc, hero_image: $hero, name: $name, order: $order, updated_at: $updated_at}){
        affected_rows
			}
		}
	`
};
export const FETCH_ERA_DETAILS = {
  query: `
		query era_by_pk($id: Int!){
			era_by_pk(id: $id){
        id
        description
        name
        hero_image
        order
        monuments(where: {is_active: {_eq: true}}) {
           id
          details
          updated_at
        }
			}
		}
  	`
};

export const FETCH_ALL_MONUMENTS = {
  query: `
		query monuments( $order: [monuments_order_by!], $name: String!, $limit: Int, $offset: Int, $status: Boolean){
      monuments(order_by: $order, where: {name: {_ilike: $name}, status: {_eq: $status}}, limit: $limit, offset: $offset) {
        id
        location
        name
        details
        era {
          name
        }
        updated_at
        status
      }
      reviews_aggregate {
        aggregate {
          count
        }
      }
      monuments_aggregate {
        aggregate {
          count
        }
      }
		}
  	`
};

export const FETCH_ALL_MONUMENTS_V2 = {
  query: `
		query monuments( $order: [monuments_order_by!], $where: monuments_bool_exp, $limit: Int, $offset: Int){
      monuments(order_by: $order, where: $where, limit: $limit, offset: $offset) {
        id
        location
        name
        details
        era {
          name
        }
        updated_at
        status
      }
      reviews_aggregate {
        aggregate {
          count
        }
      }
      monuments_aggregate (where: $where) {
        aggregate {
          count
        }
      }
		}
  	`
};

export const FETCH_ALL_MONUMENTS_BY_STATUS = {
  query: `
		query monuments($status: Boolean!){
      monuments(where: {status: {_eq: $status}}) {
        id
        location
        name
        details
        era {
          name
        }
        updated_at
        status
      }
		}
  	`
};

export const INSERT_MONUMENTS = {
  query: `
		mutation insert_monuments($objects: [monuments_insert_input!]!){
			insert_monuments(objects: $objects){
        affected_rows
        returning {
          id
        }
			}
		}
	`
};

export const FETCH_ALL_REVIEWS = {
  query: `
    query reviews($status: String!, $limit: Int, $offset: Int, $order: [reviews_order_by!], $searchByName: String, $searchByUserName: String) {
      reviews(where: {status: {_eq: $status}, monument : {name: {_ilike: $searchByName}, is_active: {_eq: true}}, user: {username: {_ilike: $searchByUserName}}}, limit: $limit, offset: $offset, order_by: $order) {
        comment
        id
        created_at
        rating
        user {
          email
          username
        }
        monument {
          name: details(path: "mt_name")
        }
      }
      reviews_aggregate(where: {status: {_eq: $status}, monument: {is_active: {_eq: true}, name: {_ilike: $searchByName}}, user: {username: {_ilike: $searchByUserName}}}) {
        aggregate {
          count
          avg {
              rating
            }
        }
      }
    }
	`
};

// $order: [reviews_order_by!], $searchByName: String, $searchByUserName: String
// monument : {name: {_ilike: $searchByName}}, user: {username: {_ilike: $searchByUserName}}}, order_by: $order

export const FETCH_ALL_APPROVED_REVIEWS_GROUPED_BY_MONUMENTS = {
  query: `
    query monuments($limit: Int, $offset: Int, $order: [monuments_order_by!], $searchByName: String) {
      monuments(where: {name: {_ilike: $searchByName}, is_active: {_eq: true}, reviews: {status: {_eq: "approved"}}}, limit: $limit, offset: $offset, order_by: $order) {
        id
        name: details(path: "mt_name")
        reviews{
          id
          comment
          rating
          user {
            email
            username
          }
        }
        reviews_aggregate(where: {status: {_eq: "approved"}}) {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
      }
    }
  `
};

export const APPROVE_OR_REJECT_REVIEW = {
  query: `
      mutation update_reviews($id: Int!, $status: String!){
        update_reviews(where: {id: {_eq: $id}}, _set: {status: $status}){
          affected_rows
        }
      }
    `
};

export const FETCH_REVIEW_DETAILS = {
  query: `
		query reviews_by_pk($id: Int!){
			reviews_by_pk(id: $id){
          user {
            id
            avatar
            username
            email
            reviews_aggregate (where: {status: {_eq: "approved"}}) {
              aggregate {
                count
              }
            }
          }
          comment
          is_highlighted
          id
          created_at
          rating
          status
          monument {
          details(path: "mt_name")
        }
			}
		}
  	`
};

export const FETCH_REVIEWS_BY_USER = {
  query: `
		query users_by_pk($id: Int!){
			users_by_pk(id:$id){
         reviews(where: {status: {_eq: "approved"}}) {
          monument {
            details(path: "mt_name")
          }
          comment
          rating
          created_at
        }
			}
		}
  	`
};

export const FETCH_REVIEWS_BY_MONUMENT = {
  query: `
		query monuments_by_pk($id: Int!, $order_by: [reviews_order_by!]){
			monuments_by_pk(id:$id){
        name
        reviews(order_by: $order_by, where: {status: {_eq: "approved"}}) {
          id
          comment
          rating
          is_highlighted
          updated_at
          user {
            username
            avatar
          }
          status
        }
			}
		}
  `
};

export const FETCH_MONUMENT_BY_PK = {
  query: `
		query monuments_by_pk($id: Int!){
			monuments_by_pk(id:$id){
        status
				details
        location_name
        name
				id
    		era_id
				era {
					name
        }
        airport {
          id
          name
          location
        }
        monuments_x_tags {
					tag {
						id
						name
					}
				}
				location
				micro_monuments (order_by: {id: asc}, where: {is_active: {_eq: true}}) {
					id
					name
					video_url
          hero_image
          is_active
				}
			}
		}
	`
};

export const TOGGLE_HIGHLIGHT_FOR_REVIEW = {
  query: `
      mutation update_reviews($id: Int!, $is_highlighted: Boolean!){
        update_reviews(where: {id: {_eq: $id}}, _set: {is_highlighted: $is_highlighted}){
          affected_rows
        }
      }
    `
};

export const UPDATE_ERA_ORDER = {
  query: `
      mutation update_era($id: Int!, $order: Int!){
        update_era(where: {id: {_eq: $id}}, _set: {order: $order}){
          affected_rows
        }
      }
    `
};

export const UPDATE_MONUMENTS = {
  query: `
		mutation update_monuments($setObject: monuments_set_input, $id: Int!){
			update_monuments(_set: $setObject, where: {id: {_eq: $id}} ){
        affected_rows
			}
		}
	`
};

export const DELETE_MICRO_MONUMENTS = {
  query: `
		mutation delete_micro_monuments($id: Int!){
			update_micro_monuments(where: {id: {_eq: $id}}, _set: {is_active: false}) {
				affected_rows
			}
		}
	`
};

export const UPDATE_MICRO_MONUMENTS = {
  query: `
		mutation update_micro_monuments($setObject: micro_monuments_set_input, $id: Int!){
			update_micro_monuments(_set: $setObject, where: {id: {_eq: $id}} ){
        affected_rows
			}
		}
	`
};

export const INSERT_MICRO_MONUMENTS = {
  query: `
		mutation insert_micro_monuments($objects: [micro_monuments_insert_input!]!){
			insert_micro_monuments(objects: $objects){
        affected_rows
			}
		}
	`
};

export const FETCH_MICRO_MONUMENT = {
  query: `
		query micro_monuments($id: Int!){
			micro_monuments(where: {monument_id: {_eq: $id}, is_active: {_eq: true}}, order_by: {id: asc}) {
				id
				name
				video_url
        hero_image
        is_active
			}
		}
	`
};

export const INSERT_ALL_MICRO_MONUMENTS = {
  query: `
		mutation insert_micro_monuments($objects: [micro_monuments_insert_input!]!){
  		insert_micro_monuments(objects: $objects) {
    		affected_rows
  		}
		}
	`
};

export const UPDATE_ALL_MICRO_MONUMENTS = {
  query: `
		mutation update_micro_monuments($id: Int!, $set: micro_monuments_set_input){
  		update_micro_monuments(where: {id: {_eq: $id}}, _set: $set) {
				affected_rows
			}
		}
	`
};

export const DELETE_MONUMENT = {
  query: `
		mutation update_monuments($id: Int!) {
			update_monuments(_set: {is_active: false}, where: {id: {_eq: $id}}) {
				affected_rows
			}
			update_micro_monuments(where: {monument_id: {_eq: $id}}, _set: {is_active: false}) {
				affected_rows
			}
		}
	`
};

export const INSERT_AIRPORT = {
  query: `
    mutation insert_airport ($name: String!, $location: geography!) {
      __typename
        insert_airports(objects: {name: $name, location: $location}, on_conflict: {constraint: airports_name_key, update_columns: name}) {
          returning {
            id
            name
            location
          }
        }
      }
  `
};

export const GET_ALL_TAGS = {
  query: `
		query tags{
			tags {
				id
				name
			}
		}
	`
};

export const ADD_TAG = {
  query: `
		mutation tags($objects: [tags_insert_input!]!) {
			insert_tags(objects: $objects) {
				affected_rows
				returning {
					id
					name
				}
			}
		}
	`
};

export const UPDATE_TAGS = {
  query: `
		mutation ($monuments_id: Int!, $objects: [monuments_x_tags_insert_input!]!){
			delete_monuments_x_tags(where: {monuments_id: {_eq: $monuments_id}}) {
				affected_rows
			}
			insert_monuments_x_tags(objects: $objects) {
				affected_rows
			}
		}
	`
};
