import React, { Component } from 'react';
// import { hot } from 'react-hot-loader'
import './App.css';
import Main from './main';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Main/>
      </div>
    );
  }
}

// export default hot(module)(App);
export default App;
