import React from "react";
import { Form, Icon, Input, Modal, Upload } from "antd";
import {signAUrl, uploadFiles} from "./util";

class AddMicroMonuForm extends React.Component {
  state = {
    fileList: [],
    fileListUpload: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        if (this.props.editMode) {
          this.props.onEditMicro(values);
        } else {
          this.props.onAddMicro(values);
        }
      }
    });
  };

  heroImageChange = (file, index) => {
    this.props.form.setFieldsValue({ "hero_img": file[0] ? file[0].response : "" });
    const state = { ...this.state };
    state.fileList = file;
    this.setState(state);
  };

  onFileChange = ({ file, fileList }) => {
    this.setState({
      fileList: fileList
    });
    this.props.form.setFieldsValue({ "hero_img": fileList.length ? fileList[0].response : null });
  };

  componentDidMount() {
    if (this.props.editMode) {
      this.setState({
        fileList: [{
          uid: 1,
          name: 'image',
          url: this.props.initials.hero_image,
          response: this.props.initials.hero_image,
        }],
        fileListUpload: [this.props.initials.hero_image]
       });
      this.props.form.setFieldsValue({ "hero_img": this.props.initials.hero_image });
    }
  }

  customRequest = async ({file, onSuccess,}) => {
    const signedUploadUrl = await signAUrl(`${file.name}`, `${file.type}`);
    const filesArray = [file];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    this.setState({ fileListUpload: [uploadedResponse] });
    onSuccess(uploadedResponse, file);
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { getFieldDecorator } = this.props.form;
    const { editMode } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="addMicroMonu-form"
        style={{
          textAlign: "left"
        }}
        hideRequiredMark
      >
        <Form.Item label="Detail Name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please enter a name!" }],
            initialValue: editMode ? this.props.initials.name : ""
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Video Link">
          {getFieldDecorator("video_url", {
            rules: [
              {
                required: true,
                pattern: /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([‌​0-9]{6,11})[?]?.*/,
                message: "Please enter a valid vimeo link!"
              }
            ],

            initialValue: editMode ? this.props.initials.video_url : null
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Hero Image">
          {getFieldDecorator("hero_img", {
            rules: [{ required: true, message: "Please add an image" }]
          })(
            <React.Fragment>
              <Upload
                name="logo"
                defaultFileList={this.state.fileList}
                fileList={this.state.fileList}
                listType="picture-card"
                customRequest={this.customRequest}
                onChange={this.onFileChange}
                onPreview={this.handlePreview}
                accept=".jpg, .jpeg, .png"
              >
                {this.state.fileList.length === 0 ? (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                ) : null}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </Modal>
            </React.Fragment>
          )}
        </Form.Item>
      </Form>
    );
  }
}

export const WrappedAddMicroMonuForm = Form.create({ name: "normal_login" })(
  AddMicroMonuForm
);
