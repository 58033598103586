// NOTE: for all validations, refer monument-validations.md

import React, { Component } from "react";
import Header from "./header";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Empty,
  Drawer,
  Popconfirm,
  Alert,
  Spin
} from "antd";
import {
  FETCH_ALL_ERA,
  FETCH_MONUMENT_BY_PK,
  UPDATE_MONUMENTS,
  FETCH_MICRO_MONUMENT,
  UPDATE_ALL_MICRO_MONUMENTS,
  INSERT_ALL_MICRO_MONUMENTS,
  INSERT_AIRPORT,
  UPDATE_TAGS
} from "./graphql";
import { API_ROOT, openNotification } from "./util";
import axios from "axios";
import { WrappedAddMicroMonuForm } from "./addMicroMonuForm";
import moment from "moment";
import { RenderMicro } from "./renderMicro";
import { WrappedEditMonument } from "./editMonumentForm";
import { Prompt } from "react-router-dom";
const { Title } = Typography;
export default class EditMonument extends Component {
  state = {
    shouldBlockNavigation: false,
    drawerVisible: false,
    loading: false,
    era: [],
    microDetails: [],
    microEditMode: false,
    monuments_by_pk: {
      status: false,
      details: {
        mt_name: "",
        isActiveOnApp: "yes"
      },
      era: {
        name: ""
      },
      location: {
        coordinates: []
      },
      monuments: [],
      micro_monuments: [],
      monuments_x_tags: []
    }
  };

  shapeShiftMicro = () => {
    let microObj = this.state.microDetails.map((dt, index) => {
      return {
        name: dt.name,
        hero_image: dt.hero_image,
        video_url: dt.video_url,
        order: index
      };
    });
    return microObj;
  };
  shapeShiftMonu = vals => {
    let vls = { ...vals };
    vls.mt_heroImg = this.state.fileListUpload;
    vls.mt_openFrom = moment(vls.mt_openFrom).format("HH:mm");
    vls.mt_openTill = moment(vls.mt_openTill).format("HH:mm");
    // vls.mt_heroImg =
    //   vls.mt_heroImg !== undefined ? vls.mt_heroImg[0].thumbUrl : null;
    // vls.mt_openFrom =
    //   vls.mt_openFrom !== undefined
    //     ? moment(vls.mt_openFrom).format("HH:mm a")
    //     : null;
    // vls.mt_openTill =
    //   vls.mt_openTill !== undefined
    //     ? moment(vls.mt_openTill).format("HH:mm a")
    //     : null;
    console.log("shapeShiftMonu", vls);
    return vls;
  };
  onSaveDraft = values => {
    this.setState({
      loading: false
    });
    const {
      getFieldValue,
      getFieldsValue,
      validateFields,
      setFields
    } = this.formRef.props.form;
    setFields({
      mt_heroImg: {
        value: getFieldValue("mt_heroImg"),
        errors: undefined
      },
      mt_era: {
        value: getFieldValue("mt_era"),
        errors: undefined
      },
      mt_location: {
        value: getFieldValue("mt_location"),
        errors: undefined
      },
      mt_video: {
        value: getFieldValue("mt_video"),
        errors: undefined
      },
      mt_description: {
        value: getFieldValue("mt_description"),
        errors: undefined
      },
      mt_gallery: {
        value: this.formRef.state.fileList4gallery.length
          ? this.formRef.state.fileList4gallery.map(file => file.response)
          : undefined,
        errors: undefined
      },
      mt_openFrom: {
        value: getFieldValue("mt_openFrom"),
        errors: undefined
      },
      mt_openTill: {
        value: getFieldValue("mt_openTill"),
        errors: undefined
      }
    });
    validateFields(["mt_name", "mt_location", "mt_era"], (err, val) => {
      let fromDraft = true;
      if (!err) {
        this.insertMonuments(
          getFieldsValue(),
          this.shapeShiftMicro(),
          this.state.microDetails.length > 0,
          fromDraft
        );
      } else {
        openNotification("error", "Please Enter All Required Fields");
        this.setState({ loading: false });
      }
    });
  };
  onEditMonument = values => {
    console.log("this.formRef", this.formRef);
    const {
      getFieldValue,
      getFieldsValue,
      setFields,
      validateFieldsAndScroll
    } = this.formRef.props.form;
    let isActiveOnApp = getFieldValue("mt_isActiveOnApp");
    console.log(isActiveOnApp);
    let fieldsWhenNotActive = [
      "mt_name",
      "mt_location",
      "mt_era",
      "mt_heroImg"
    ];
    setFields({
      mt_gallery: {
        value: this.formRef.state.fileList4gallery.length
          ? this.formRef.state.fileList4gallery.map(file => file.response)
          : [],
        errors: undefined
      },
      mt_heroImg: {
        value:
          this.formRef.state.fileList.length &&
          this.formRef.state.fileList.filter(f => f.response).length
            ? this.formRef.state.fileList.map(file => file.response)
            : [],
        errors: this.formRef.state.fileList.length
          ? undefined
          : "Please upload header img"
      }
    });
    if (isActiveOnApp === "no") {
      validateFieldsAndScroll(fieldsWhenNotActive, (err, val) => {
        if (!err) {
          let fwnA = getFieldsValue();
          this.insertMonuments(
            this.shapeShiftMonu(fwnA),
            this.shapeShiftMicro(),
            this.state.microDetails.length > 0,
            false
          );
        } else {
          openNotification("error", "Please Enter All Required Fields");
        }
        setFields({
          mt_video: {
            value: getFieldValue("mt_video"),
            errors: undefined
          },
          mt_description: {
            value: getFieldValue("mt_description"),
            errors: undefined
          },
          mt_gallery: {
            value: this.formRef.state.fileList4gallery.length
              ? this.formRef.state.fileList4gallery.map(file => file.response)
              : undefined,
            errors: undefined
          },
          mt_openFrom: {
            value: getFieldValue("mt_openFrom"),
            errors: undefined
          },
          mt_openTill: {
            value: getFieldValue("mt_openTill"),
            errors: undefined
          }
        });
      });
    } else {
      validateFieldsAndScroll(
        (err, val) => {
          if (!err) {
            let fwnA = getFieldsValue();
            console.log("fwnA", fwnA);

            this.insertMonuments(
              this.shapeShiftMonu(fwnA),
              this.shapeShiftMicro(),
              this.state.microDetails.length > 0
            );
          } else {
            openNotification("error", "Please Enter All Required Fields");
          }
        },
        { force: true }
      );
    }
  };

  fetchAllEra = responseJson => {
    let query = FETCH_ALL_ERA;

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          era: res.data.data.era,
          loading: false,
          selectedEra:
            this.props.location.state !== undefined &&
            this.props.location.state.hasOwnProperty("selectedEra")
              ? this.props.location.state.selectedEra
              : null
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true
    });
  };

  hideDrawer = () => {
    this.setState({
      drawerVisible: false,
      microEditMode: false
    });
  };

  checkIfUploadHappened = () => {
    const {
      validateFields,
      setFields,
      getFieldValue
    } = this.formRefMicro.props.form;
    validateFields((e, v) => {
      if (e) {
        console.log(e);
      } else {
        this.onMicroSubmit(v);
      }
    });
  };

  onMicroSubmit = values => {
    console.log(this.formRefMicro);
    if (this.state.microEditMode) {
      let stateCopy = { ...this.state };
      let newDeet = {
        id: this.state.microDetails[this.state.currentIndexToEdit].id,
        name: values.name,
        video_url: values.video_url,
        hero_image: this.formRefMicro.state.fileListUpload[0],
        upload: this.formRefMicro.state.fileList,
        is_active: true
      };
      stateCopy.microDetails.splice(this.state.currentIndexToEdit, 1, newDeet);
      stateCopy.microEditMode = false;
      this.setState(stateCopy);
      this.hideDrawer();
    } else {
      let stateCopy = { ...this.state };
      let newDeet = {
        name: values.name,
        video_url: values.video_url,
        hero_image: this.formRefMicro.state.fileListUpload[0],
        upload: this.formRefMicro.state.fileList,
        is_new: true,
        is_active: true
      };
      stateCopy.microDetails = [...stateCopy.microDetails, newDeet];
      this.setState(stateCopy);
      this.hideDrawer();
    }
  };

  updateTags = async tag_ids => {
    let mutation = UPDATE_TAGS;
    mutation.variables = {
      monuments_id: parseInt(this.props.match.params.id),
      objects: tag_ids.map(tag => ({
        tags_id: tag,
        monuments_id: parseInt(this.props.match.params.id)
      }))
    };

    axios
      .post(API_ROOT, mutation)
      .then(res => console.log(res))
      .catch(err => console.error(err));
  };

  updateAllMicros = async () => {
    console.log("UpdateAllMicro");

    // filter all is_new
    let newlyAddedMicro = this.state.microDetails.filter(
      x => x.is_new === true
    );
    // filter all is_new
    let existingMicro = this.state.microDetails.filter(
      x => x.is_new === undefined
    );
    // update array
    let update_array = [];
    // set variables for update array
    let update_all_mutation = UPDATE_ALL_MICRO_MONUMENTS;
    let variables = existingMicro.map(mic => {
      update_array.push({
        id: mic.id,
        set: {
          name: mic.name,
          video_url: mic.video_url,
          hero_image: mic.hero_image,
          monument_id: parseInt(this.props.match.params.id),
          is_active: mic.is_active
        }
      });
    });

    console.log("update_array", update_array);

    existingMicro.map((items, index) => {
      axios
        .post(API_ROOT, {
          query: UPDATE_ALL_MICRO_MONUMENTS.query,
          variables: update_array[index]
        })
        .then(res => console.log(res))
        .catch(err => console.error(err));
    });

    let insert_mutation = INSERT_ALL_MICRO_MONUMENTS;
    insert_mutation.variables = {
      objects: newlyAddedMicro.map(mic => ({
        name: mic.name,
        video_url: mic.video_url,
        hero_image: mic.hero_image,
        monument_id: parseInt(this.props.match.params.id)
      }))
    };

    // let delete_res = await axios.post(API_ROOT, update_all_mutation, headers);
    let insert_res = await axios.post(API_ROOT, insert_mutation);
    // console.log(delete_res, insert_res);
    this.setState({ loading: false }, () => this.fetchMicroMonuments());
  };

  insertMonuments = async (reqObj, microObj, withMicro, fromDraft = false) => {
    const {
      address,
      lat,
      lng,
      fileList,
      fileList4gallery,
      fileList4galleryUpload,
      fileListUpload,
      mt_lat,
      mt_lng,
      tag_ids
    } = this.formRef.state;
    let mutation = UPDATE_MONUMENTS;
    let fListArr = [];
    let fList = fileList.map(x => fListArr.push(x.response));
    let fListGArr = [];
    let fListG = fileList4gallery.map(x => fListGArr.push(x.response));

    let airport_data = {
      name: address,
      location: {
        type: "Point",
        coordinates: [lat, lng]
      }
    };

    let airport_mutation = INSERT_AIRPORT;
    airport_mutation.variables = airport_data;
    let airport_id = null;

    await axios
      .post(API_ROOT, airport_mutation)

      .then(res => {
        console.log("insertAirports", res.data);

        airport_id = res.data.data.insert_airports.returning[0].id;

        // this.setState({
        //   loading: false,
        //   shouldBlockNavigation: false
        // });
        // openNotification("success", "Monument has been published");
        // // if (res.data.hasOwnProperty("insert_monuments")) {
        // // }
        // // this.props.history.goBack();
        // this.props.history.push("/monuments");
      })
      .catch(err => {
        console.error(err);
      });

    // mt_formattedAddress: address,
    // mt_mapLocation: address

    let stObject = {
      details: {
        ...reqObj,
        mt_gallery: fileList4gallery.length
          ? fileList4gallery.map(file => file.response)
          : [],
        mt_heroImg: fileList.length ? fileList.map(file => file.response) : [],
        mt_formattedAddress: this.state.address,
        mt_mapLocation: address
        // mt_openFrom: moment(reqObj.mt_openFrom).format("HH:mm"),
        // mt_openTill: moment(reqObj.mt_openTill).format("HH:mm")
      },
      era_id: reqObj.mt_era !== undefined ? reqObj.mt_era : null,
      location: {
        type: "Point",
        coordinates: [parseFloat(reqObj.mt_lat), parseFloat(reqObj.mt_long)]
      },
      airport_id: airport_id,
      location_name: reqObj.mt_location,
      name: reqObj.mt_name,
      status: !fromDraft,
      last_updated: moment()
    };
    let draft = {
      details: {
        ...reqObj,
        mt_gallery: fileList4gallery.length
          ? fileList4gallery.map(file => file.response)
          : [],
        mt_heroImg: fileList.length ? fileList.map(file => file.response) : [],
        mt_formattedAddress: this.state.address,
        mt_mapLocation: address,
        mt_openFrom: moment(reqObj.mt_openFrom).format("HH:mm"),
        mt_openTill: moment(reqObj.mt_openTill).format("HH:mm")
      },
      era_id: reqObj.mt_era !== undefined ? reqObj.mt_era : null,
      location: {
        type: "Point",
        coordinates: [parseFloat(reqObj.mt_lat), parseFloat(reqObj.mt_long)]
      },
      airport_id: airport_id,
      location_name: reqObj.mt_location,
      name: reqObj.mt_name,
      status: !fromDraft,
      last_updated: moment()
    };
    let wMicro = {
      setObject: fromDraft ? draft : stObject,
      id: parseInt(this.props.match.params.id)
    };

    console.log(wMicro);

    mutation.variables = wMicro;

    if (wMicro.setObject.details.mt_gallery.length) {
      let error = false;
      wMicro.setObject.details.mt_gallery.map(img => {
        if (img === undefined || img === null) {
          error = true;
        }
      });
      if (error) {
        openNotification(
          "error",
          "Gallery upload is in progress. Please try after the upload has been completed"
        );
        return null;
      }
    }

    if (wMicro.setObject.details.mt_heroImg.length) {
      console.log("UPload failed");
      if (
        wMicro.setObject.details.mt_heroImg[0] === undefined ||
        wMicro.setObject.details.mt_heroImg[0] === null
      ) {
        console.log("UPload failed");
        openNotification("error", "Hero image upload failed!");
        return null;
      }
    }
    // mutation.variables = withMicro ? wMicro : woMicro;

    console.log(reqObj.mt_isActiveOnApp);

    if (airport_id) {
      if (!fromDraft) {
        if (reqObj.mt_isActiveOnApp === "no") {
          console.log("Monument is not active on app");
          this.updateAllMicros();
          this.updateTags(tag_ids);
          axios
            .post(API_ROOT, mutation)
            .then(res => {
              console.log("insertMonuments", res.data);
              this.setState({
                loading: false,
                shouldBlockNavigation: false
              });
              if (res.data.data.hasOwnProperty("update_monuments")) {
                openNotification(
                  "success",
                  "Congratulations! The monument has been published"
                );
                setTimeout(() => {
                  this.props.history.push(
                    `/monuments/${this.props.match.params.id}`
                  );
                }, 500);
              }
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          if (
            this.state.microDetails.filter(x => x.is_active === true).length ===
              0 ||
            this.state.microDetails.filter(x => x.is_active === true).length %
              2 !==
              0
          ) {
            this.setState({ loading: false });
            openNotification(
              "error",
              "Micro details should be uploaded in even numbers"
            );
          } else {
            this.updateAllMicros();
            this.updateTags(tag_ids);
            axios
              .post(API_ROOT, mutation)
              .then(res => {
                console.log("insertMonuments", res.data);

                this.setState({
                  loading: false,
                  shouldBlockNavigation: false
                });
                if (res.data.data.hasOwnProperty("update_monuments")) {
                  openNotification(
                    "success",
                    "Congratulations! The monument has been published"
                  );
                  setTimeout(() => {
                    this.props.history.push(
                      `/monuments/${this.props.match.params.id}`
                    );
                  }, 500);
                }
              })
              .catch(err => {
                console.error(err);
              });
          }
        }
      } else {
        this.updateAllMicros();
        this.updateTags(tag_ids);
        axios
          .post(API_ROOT, mutation)
          .then(res => {
            console.log("insertMonuments", res.data);
            this.setState({
              loading: false,
              shouldBlockNavigation: false
            });
            if (res.data.data.hasOwnProperty("update_monuments")) {
              openNotification(
                "success",
                this.state.monuments_by_pk.status
                  ? "Monument was unpublished and the updated details saved as draft."
                  : "Successfully saved as draft"
              );
              this.props.history.push(
                `/monuments/${this.props.match.params.id}`
              );
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  };
  onConfirmDeletion = id => {
    let stateCopy = { ...this.state };
    let currentMD = stateCopy.microDetails.findIndex(x => x.id === id);
    console.log(currentMD);
    stateCopy.microDetails[currentMD].is_active = false;
    this.setState(stateCopy);
  };

  fetchMonumentByPK = responseJson => {
    let query = FETCH_MONUMENT_BY_PK;
    // let token =  localStorage.getItem('token')
    console.log(this.props.match.params.id);
    query.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchMonumentByPK", res.data);
        this.setState({
          monuments_by_pk: res.data.data.monuments_by_pk,
          microDetails: res.data.data.monuments_by_pk.micro_monuments,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };
  fetchMicroMonuments = () => {
    let query = FETCH_MICRO_MONUMENT;
    query.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchMicroMonuments", res.data);
        this.setState({
          microDetails: res.data.data.micro_monuments,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchAllEra();
    this.fetchMonumentByPK();
    this.fetchMicroMonuments();
    this.setState({ shouldBlockNavigation: true });
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Spin tip="Loading..." spinning={this.state.loading}>
          <Header />

          <div
            style={{
              margin: "2rem 10rem"
            }}
          >
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: "1rem" }}
            >
              <Col
                span={8}
                style={{
                  textAlign: "left"
                }}
              >
                Edit Monument
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "right"
                }}
              >
                <Button
                  type="danger"
                  style={{
                    marginRight: "1rem"
                  }}
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
                {!this.state.monuments_by_pk.status && (
                  <Button
                    type="default"
                    onClick={this.onSaveDraft}
                    style={{
                      marginRight: "1rem"
                    }}
                  >
                    Save as draft
                  </Button>
                )}
                <Button type="primary" onClick={this.onEditMonument}>
                  Publish
                </Button>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Card>
                  <Row type="flex" justify="center">
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Title level={3}>Monument Details</Title>
                      <WrappedEditMonument
                        data={this.state.monuments_by_pk}
                        selectedEra={this.state.selectedEra}
                        era={this.state.era}
                        onEditMonument={this.onEditMonument}
                        wrappedComponentRef={inst => (this.formRef = inst)}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Row type="flex" justify="center">
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Title level={3}>Monument Micro Details</Title>

                      {this.state.microDetails.length > 0 ? (
                        <React.Fragment>
                          {this.state.microDetails
                            .filter(x => x.is_active === true)
                            .map((deet, index) => {
                              return (
                                <Card
                                  key={index}
                                  size="small"
                                  title={deet.name}
                                  extra={
                                    <React.Fragment>
                                      <Popconfirm
                                        title="This action cannot be undone. Do you wish to proceed?"
                                        onConfirm={this.onConfirmDeletion.bind(
                                          this,
                                          deet.id
                                        )}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          Delete
                                        </a>
                                      </Popconfirm>
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          this.setState({
                                            microEditMode: true,
                                            drawerVisible: true,
                                            currentItemToEdit: deet,
                                            currentIndexToEdit: index
                                          });
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </React.Fragment>
                                  }
                                  style={{
                                    marginBottom: "1.25rem"
                                  }}
                                >
                                  <RenderMicro data={[deet]} index={index} />
                                </Card>
                              );
                            })}
                          {this.state.microDetails.filter(
                            x => x.is_active === true
                          ).length %
                            2 !==
                          0 ? (
                            <Alert
                              message="Please note!"
                              description="Micro details should be uploaded in even numbers"
                              type="warning"
                              showIcon
                            />
                          ) : null}
                          <Button
                            type="primary"
                            onClick={this.showDrawer}
                            style={{ marginTop: "1rem" }}
                          >
                            Add New
                          </Button>
                        </React.Fragment>
                      ) : (
                        // TODO: [MU-18] Show even number warning
                        <Empty
                          imageStyle={{
                            height: "11.25rem"
                          }}
                          description={<span>No micro detail</span>}
                        >
                          <Button type="primary" onClick={this.showDrawer}>
                            Add New
                          </Button>
                        </Empty>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
          <React.Fragment>
            <Drawer
              destroyOnClose
              width="45%"
              title={
                <div>
                  <Row type="flex" justify="space-between">
                    <Col span={8}>Add New Micro Detail</Col>
                    <Col
                      span={8}
                      style={{
                        textAlign: "right"
                      }}
                    >
                      <Button
                        type="danger"
                        style={{ marginRight: "1rem" }}
                        onClick={this.hideDrawer}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={this.checkIfUploadHappened}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
              placement="right"
              closable={false}
              visible={this.state.drawerVisible}
            >
              <WrappedAddMicroMonuForm
                editMode={this.state.microEditMode}
                initials={this.state.currentItemToEdit}
                onMicroSubmit={this.onMicroSubmit}
                // onMicroSubmit={this.checkIfUploadHappened}
                wrappedComponentRef={inst => (this.formRefMicro = inst)}
              />
            </Drawer>
          </React.Fragment>
        </Spin>
      </React.Fragment>
    );
  }
}
