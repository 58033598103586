import React from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import { openNotification } from "../util";
import { myFirebase } from "./firebase";
import jwt_decode from "jwt-decode";
class Login extends React.Component {
  state = {
    loading: false,
    email: "",
    password: ""
  };
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // localStorage.setItem('isLoggedIn', true )
        myFirebase
          .auth()
          .signInWithEmailAndPassword(this.state.email, this.state.password)
          .then(async res => {
            let parsed_jwt = jwt_decode(res.user.xa);
            let role =
              parsed_jwt["https://hasura.io/jwt/claims"][
                "x-hasura-default-role"
              ];
            if (role === "admin") {
              console.log(res.user);
              localStorage.setItem("token", res.user.xa);
              let idToken = await myFirebase
                .auth()
                .currentUser.getIdToken(true);

              // console.log("idToken", idToken);

              localStorage.setItem("token", idToken);

              localStorage.setItem("refreshToken", res.user.refreshToken);
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("role", role);
              sessionStorage.setItem("expiring_at", parsed_jwt.exp);

              this.setState({ loading: false }, () => {
                this.props.history.push("/era");
              });
            } else if (role === "user") {
              this.setState({ loading: false }, () => {
                openNotification("error", `You are not authorized`);
              });
            }
          })
          .catch(error => {
            // Handle Errors here.
            this.setState(
              {
                loading: false
              },
              () => {
                let { message } = error;
                openNotification("error", `${message}`);
              }
            );
          });
      }
    });
  };

  onChangeText = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="LoginForm">
        <Row gutter={16} type="flex" justify="space-around" align="middle">
          <Col span={8}>
            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    required: true,
                    message: "Please enter valid credential"
                  }
                ],
                initialValue: this.state.email
              })(
                <Input
                  name="email"
                  onChange={e => this.onChangeText(e)}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a valid Password"
                  }
                ],
                initialValue: this.state.password
              })(
                <Input
                  name="password"
                  onChange={e => this.onChangeText(e)}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item
              style={{
                marginTop: "1rem"
              }}
            >
              <Button
                disabled={this.state.password.length < 5}
                loading={this.state.loading}
                type="primary"
                onClick={this.handleSubmit}
                block
                className="themedButton"
              >
                Login
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export const LoginForm = Form.create({ name: "Login" })(Login);
