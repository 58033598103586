import React, { Component } from "react";
import Header from "./header";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Empty,
  List,
  Avatar,
  Icon,
  Breadcrumb
} from "antd";
import {FETCH_ERA_DETAILS, UPDATE_SUBMISSIONS} from "./graphql";
import { API_ROOT } from "./util";
import axios from "axios";
import { WrappedNormalLoginForm } from "./addEraForm";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

export default class ViewEra extends Component {
  state = {
    loading: false,
    edit_mode: false,
    era_by_pk: {
      monuments: []
    }
  };
  fetchEraByPK = responseJson => {
    let query = FETCH_ERA_DETAILS;
    // let token =  localStorage.getItem('token')
    query.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          era_by_pk: res.data.data.era_by_pk,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  onAddEra = values => {
    console.log(this.formRefEdit);
    this.formRefEdit.props.form.setFields({
      upload: {
        value: this.formRefEdit.state.fileListUpload[0],
        errors: this.formRefEdit.state.fileListUpload.length
          ? undefined
          : "please upload image"
      }
    });
    this.formRefEdit.props.form.validateFields((err, values) => {
      if (!err) {
        let mutation = UPDATE_SUBMISSIONS;
        mutation.variables = {
          id: this.props.match.params.id,
          desc: values.description,
          hero: this.formRefEdit.state.fileListUpload[0],
          name: values.era,
          order: this.state.era_by_pk.order,
          updated_at: new Date().toISOString()
        };

        axios
          .post(API_ROOT, mutation)
          .then(res => {
            console.log("onAddEra", res.data);

            this.setState({
              loading: false,
              edit_mode: false
            });
            this.props.history.goBack();
          })
          .catch(err => {
            console.error(err);
          });
      }
    });
  };
  handleSubmit = e => {
    e.preventDefault();
  };
  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchEraByPK();
  }
  render() {
    const IconText = ({ type, text }) => (
      <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
      </span>
    );
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/era">Era</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Era</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col
              span={8}
              style={{
                textAlign: "right"
              }}
            >
              <React.Fragment>
                {this.state.edit_mode ? (
                  <React.Fragment>
                    <Button
                      type="danger"
                      onClick={() => this.setState({ edit_mode: false })}
                      style={{ marginRight: "1rem" }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" onClick={this.onAddEra}>
                      Save
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => this.setState({ edit_mode: true })}
                  >
                    Edit
                  </Button>
                )}
              </React.Fragment>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Card>
                <Row type="flex" justify="center">
                  <Col
                    span={12}
                    style={{
                      textAlign: "left"
                    }}
                  >
                    {this.state.edit_mode ? (
                      <React.Fragment>
                        <WrappedNormalLoginForm
                          name={this.state.era_by_pk.name}
                          img={this.state.era_by_pk.hero_image}
                          desc={this.state.era_by_pk.description}
                          onAddEra={this.onAddEra}
                          wrappedComponentRef={inst =>
                            (this.formRefEdit = inst)
                          }
                          editMode
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Title level={3}>Era Details</Title>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Era</Text>
                          <Paragraph>{this.state.era_by_pk.name}</Paragraph>
                        </Row>
                        {/* <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Header image</Text> <br />
                          <img
                            src={this.state.era_by_pk.hero_image}
                            alt="hero_img"
                            width="182px"
                          />
                        </Row> */}
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Description</Text>
                          <Paragraph style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.era_by_pk.description}
                          </Paragraph>
                        </Row>
                      </React.Fragment>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Card>
                <Row type="flex" justify="center">
                  <Col
                    span={8}
                    offset={6}
                    style={{
                      textAlign: "left"
                    }}
                  >
                    <Title level={4}>Monuments in this Era</Title>
                  </Col>
                  <Col span={10} style={{ textAlign: "right" }}>
                    {this.state.era_by_pk.monuments.length ? (
                      <Button
                        type="primary"
                        disabled={this.state.edit_mode}
                        onClick={() =>
                          this.props.history.push("/monuments/add", {
                            selectedEra: this.props.match.params.id
                          })
                        }
                      >
                        Add Monument
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                {this.state.era_by_pk.monuments.length ? (
                  <Row
                    type="flex"
                    justify="center"
                    style={{ marginTop: "1rem" }}
                  >
                    <Col
                      span={12}
                      style={{
                        textAlign: "left"
                      }}
                    >
                      <List
                        itemLayout="vertical"
                        dataSource={this.state.era_by_pk.monuments}
                        renderItem={item => (
                          <List.Item
                            actions={[
                              <span
                                style={{
                                  fontStyle: "oblique"
                                }}
                              >
                                Last Modified:
                                {`${new Date(
                                  item.updated_at
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric"
                                })}
                      ${new Date(item.updated_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true
                      })}`}
                              </span>
                            ]}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  size={150}
                                  shape="square"
                                  src={
                                    item.details.mt_heroImg
                                      ? item.details.mt_heroImg[0]
                                      : this.state.era_by_pk.hero_image
                                  }
                                />
                              }
                              title={
                                <Link to={`/monuments/${item.id}`}>
                                  {item.details.mt_name}
                                </Link>
                              }
                              description={item.details.mt_location}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Empty
                    imageStyle={{
                      height: "11.25rem"
                    }}
                    description={<span>Add Monuments in this era</span>}
                  >
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.history.push("/monuments/add", {
                          selectedEra: this.props.match.params.id
                        })
                      }
                    >
                      Add Monument
                    </Button>
                  </Empty>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
