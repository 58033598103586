import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Initialize Firebase
var config = {
  apiKey: `${process.env.REACT_APP_FIREBASE_APIKEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DB}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECTID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING}`,
  appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};

// class Firebase {
//   constructor() {
//     app.initializeApp(config);
//   }
// }
// export default Firebase;
export const myFirebase = firebase.initializeApp(config);

export const signin = (email, password) => {
    console.log("called inside signin", email, password, firebase);
    
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(function(user) {
      console.log("login success");
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(error);
    });

  let callback = null;
  let metadataRef = null;
  firebase.auth().onAuthStateChanged(user => {
    // Remove previous listener.
    if (callback) {
      metadataRef.off("value", callback);
    }
    // On user login add new listener.
    if (user) {
      // Check if refresh is required.
      metadataRef = firebase
        .database()
        .ref("metadata/" + user.uid + "/refreshTime");
      callback = snapshot => {
        // Force refresh to pick up the latest custom claims changes.
        // Note this is always triggered on first call. Further optimization could be
        // added to avoid the initial trigger when the token is issued and already contains
        // the latest claims.
        console.log("metadataRef", metadataRef);
        user.getIdToken(true);
      };
      // Subscribe new listener to changes on that node.
      metadataRef.on("value", callback);
    }
  });
};
