import React, { Component } from "react";
import Header from "./header";
import { Button, Row, Col, Icon, Input, Table } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { FETCH_ALL_ERA, UPDATE_ERA_ORDER } from "./graphql";
import { API_ROOT } from "./util";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import BodyRow from "./BodyRow";
import update from "immutability-helper";


let dragingIndex = -1;

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index
    };
  }
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

// const DraggableBodyRow = DragSource('row', rowSource, (connect, monitor) => ({
//   connectDragSource: connect.dragSource(),
//   connectDragPreview: connect.dragPreview(),
//   isDragging: monitor.isDragging(),
// }))(
//   DropTarget('row', rowTarget, (connect, monitor) => ({
//     connectDropTarget: connect.dropTarget(),
//     isDraggingHover: monitor.isOver({ shallow: true }),
//     isOver: monitor.isOver(),
//   }))(BodyRow),
// );

const DraggableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource("row", rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
  }))(BodyRow)
);

export default class AllEra extends Component {
  state = {
    loading: false,
    era: [],
    searchText: "",
    sortBy: ""
  };
  sortByLogic = () => {
    switch (this.state.sortBy) {
      case "number_ascend":
        return { monuments_aggregate: { count: "asc" } };
        break;
      case "number_descend":
        return { monuments_aggregate: { count: "desc" } };
        break;
      case "updated_at_descend":
        return { updated_at: "desc" };
        break;
      case "updated_at_ascend":
        return { updated_at: "asc" };
        break;

      default:
        return { order: "asc" };
        break;
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log(e.target.value);

            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block"
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  fetchAllEra = responseJson => {
    let query = FETCH_ALL_ERA;
    query.variables = {
      order: this.sortByLogic()
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          era: res.data.data.era,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };
  //end props
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleTableChange = (pagination, filters, sorter) => {
    if (!isEmpty(sorter)) {
      this.setState({ sortBy: `${sorter.columnKey}_${sorter.order}` }, () =>
        this.fetchAllEra()
      );
    }
    console.log(pagination, filters, sorter, isEmpty(sorter));
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchAllEra();
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { era } = this.state;
    const dragRow = era[dragIndex];
    let newState = update(this.state, {
      era: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
      }
    });
    this.setState(newState, () => {
      this.setOrderForEra();
    });
  };

  // set order for all era
  setOrderForEra = () => {
    const { era } = this.state;
    era.forEach((er, i) => {
      setTimeout(() => {
        this.updateEraOrder(er.id, i + 1, era.length - 1 === i ? true: false);
      }, 1000);
    });
  };

  updateEraOrder = (id, order) => {
    let mutation = UPDATE_ERA_ORDER;
    mutation.variables = {
      id: id,
      order: order
    };
    

    axios
      .post(API_ROOT, mutation)
      .then(res => {
        // console.log("updateEraOrder", res.data);
      })
      .catch(err => {
        // openNotification("error")
        console.error(err);
      });
  };

  components = {
    body: {
      row: DraggableBodyRow
    }
  };

  render() {
    const columns = [
      {
        title: "Era",
        dataIndex: "name",
        key: "name",
        width: 250,
        ...this.getColumnSearchProps("name")
      },
      {
        title: "No. of monuments",
        dataIndex: "monuments_aggregate.aggregate.count",
        key: "number",
        width: 250,
        sorter: true
      },
      {
        title: "Last updated at",
        dataIndex: "updated_at",
        key: "updated_at",
        width: 350,
        sorter: true,
        render: (text, record) => (
          <span>{moment(record.updated_at).format("Do MMM YYYY, h:mm a")}</span>
        )
      },
      {
        title: "Action",
        key: "action",
        width: 250,
        render: (text, record) => (
          <span>
            <Link to={`/era/${record.id}`}>Manage</Link>
          </span>
        )
      },
      {
        title: "",
        render: () => <span style={{ cursor: "move" }}>Move</span>
      }
    ];

    return (
      <React.Fragment>
        <Header />
        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row type="flex" justify="space-between">
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              Era
            </Col>
            <Col
              span={4}
              style={{
                textAlign: "right"
              }}
            >
              <Button
                type="primary"
                onClick={() => this.props.history.push("/era/add")}
              >
                Add an Era
              </Button>
            </Col>
          </Row>
          <Row type="flex">
            {this.state.era && (
              <React.Fragment>
                <DndProvider backend={HTML5Backend}>
                  <Table
                    id="dnd-table"
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.era}
                    pagination={false}
                    components={this.components}
                    onRow={(record, index) => ({
                      index,
                      moveRow: this.moveRow
                    })}
                    style={{
                      backgroundColor: "#fff",
                      margin: "auto"
                    }}
                  />
                </DndProvider>
              </React.Fragment>
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
