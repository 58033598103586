import React from "react";
import { Form, Input } from "antd";
import { signAUrl, uploadFiles } from "./util";

class NormalLoginForm extends React.Component {
  state = {
    fileList: [],
    fileListUpload: []
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        this.props.onAddEra(values);
      }
    });
  };

  onFileChange = ({ file, fileList }) => {
    this.setState({
      fileList: fileList
    });
    if (!fileList.length) {
      this.setState({ fileListUpload: [] });
    }
    this.props.form.setFieldsValue({
      upload: fileList.length ? fileList[0].response : null
    });
  };

  customRequest = async ({ file, onSuccess }) => {
    const signedUploadUrl = await signAUrl(`${file.name}`, `${file.type}`);
    const filesArray = [file];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    this.setState({ fileListUpload: [uploadedResponse] });
    onSuccess(uploadedResponse, file);
  };

  componentDidMount() {
    if (this.props.editMode) {
      console.log("edited");
      this.setState({
        fileList: [
          {
            uid: 1,
            name: this.props.name,
            status: "done",
            url: this.props.img,
            response: this.props.img
          }
        ],
        fileListUpload: [this.props.img]
      });
      this.props.form.setFieldsValue({ upload: this.props.img });
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="login-form"
        style={{
          textAlign: "left"
        }}
        hideRequiredMark
      >
        <Form.Item label="Era">
          {getFieldDecorator("era", {
            initialValue: this.props.name,
            rules: [
              { required: true, message: "Please enter a name for this era!" }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: this.props.desc,
            rules: [{ required: true, message: "Please enter description" }]
          })(<Input.TextArea rows={4} />)}
        </Form.Item>
        {/* <Form.Item label="Header image">
          {getFieldDecorator("upload", {
            rules: [{ required: true, message: "Please upload header img" }],
          })(
            <Upload
              name="logo"
              defaultFileList={this.state.fileList}
              fileList={this.state.fileList}
              listType="picture"
              customRequest={this.customRequest}
              onChange={this.onFileChange}
              accept=".jpg, .jpeg, .png"
            >
              <Button disabled={this.state.fileList.length > 0}>
                <Icon type="upload" /> Upload Hero image
              </Button>
            </Upload>
          )}
        </Form.Item> */}
      </Form>
    );
  }
}

export const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);
