import React from "react";
import { myFirebase as firebase } from "../auth/firebase";
import Header from "../header";
import {Card} from "antd";
import monumentLogo from "../assets/monumnet_logo.svg";
import invalid from "../assets/invalid.svg";
import mail_verified from "../assets/mail_verified.svg";
import reset_success_img from "../assets/reset_success.svg";
import {ResetPasswordForm} from "./ResetPassword";

class FirebaseMailVerification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: "",
			actionCode: "",
			valid_link: true,
			mail_success: false,
			mail_fail: false,
			reset_done: false,
			reset_success: false,
			reset_fail: false,
		};
	}
	
	handleResetPassword = (password) => {
		firebase.auth().verifyPasswordResetCode(this.state.actionCode).then((email) => {
			let accountEmail = email;
			firebase.auth().confirmPasswordReset(this.state.actionCode, password).then((resp) => {
				this.setState({
					reset_done: true,
					reset_success: true
				});
			}).catch((error) => {
				this.setState({
					reset_done: true,
					reset_success: false
				});
			});
		}).catch((error) => {
			this.setState({
				reset_done: true,
				reset_success: false
			});
		});
	};
	
	handleVerifyEmail = () => {
		firebase.auth().applyActionCode(this.state.actionCode).then((resp) => {
			this.setState({ mail_success: true });
		}).catch((error) => {
			this.setState({ mail_fail: true });
		});
	};
	
	componentDidMount() {
		let params = (new URL(document.location)).searchParams;
		firebase.auth().checkActionCode(params.get('oobCode')).then(info => {
			this.setState({
				mode: params.get('mode'),
				actionCode: params.get('oobCode'),
			}, () => {
				if (params.get('mode') === "verifyEmail") {
					this.handleVerifyEmail();
				}
			});
		}).catch(error => {
			this.setState({
				valid_link: false,
			})
		});
	}
	
	render() {
		const { mode , valid_link, mail_success, mail_fail, reset_success, reset_fail, reset_done } = this.state;
		return (
			<div>
				<Header />
				<div
					style={{
						marginTop: '5rem',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Card style={{ width: 525 }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<img src={monumentLogo} alt="Monument Logo" />
							{valid_link ? (
								<div style={{ width: '80%' }}>
									{mode === "resetPassword" && (
										reset_done ? (
											<>
												{reset_success && (
													<>
														<img src={reset_success_img} alt="reset_success" style={{margin: '2rem 0'}}/>
														<p>Password reset successful. You can login using your new password.</p>
													</>
												)}
												{reset_fail && (
													<>
														<img src={invalid} alt="Invalid" style={{marginTop: '2rem'}}/>
														<p>Something went wrong. Please try again.</p>
													</>
												)}
											</>
										) : (
											<ResetPasswordForm onSubmit={(values) => this.handleResetPassword(values.password)}/>
										)
									)}
									{mode === "verifyEmail" && (
										<>
											{mail_success && (
												<>
													<img src={mail_verified} alt="Mail Verified" style={{marginTop: '2rem'}}/>
													<p>Thank you for verifying your email address</p>
												</>
											)}
											{mail_fail && (
												<>
													<img src={invalid} alt="Invalid" style={{marginTop: '2rem'}}/>
													<p>Something went wrong. Please try again.</p>
												</>
											)}
										</>
									)}
								</div>
							) : (
								<>
									<img src={invalid} alt="Invalid" style={{marginTop: '2rem'}}/>
									<p>The URL is invalid. Please try again.</p>
								</>
							)}
						</div>
					</Card>
				</div>
			</div>
		);
	}
}

export default FirebaseMailVerification;
