import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import {
  FETCH_REVIEW_DETAILS,
  FETCH_REVIEWS_BY_USER,
  APPROVE_OR_REJECT_REVIEW
} from "./graphql";
import {
  Rate,
  Row,
  Col,
  Card,
  Button,
  Typography,
  Skeleton,
  Avatar,
  Icon,
  Modal,
  List,
  Breadcrumb
} from "antd";
import { API_ROOT } from "./util";
import axios from "axios";
import moment from "moment";

const { Paragraph, Text } = Typography;

export default class ViewReview extends Component {
  state = {
    loading: false,
    reviewsByUser: false,
    reviews_by_pk: [],
    reviews_by_user: []
  };
  fetchReviewByPK = responseJson => {
    this.setState({ loading: true });
    let query = FETCH_REVIEW_DETAILS;
    query.variables = {
      id: this.props.match.params.id
    };
    

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log(res.data);

        this.setState({
          loading: false,
          reviews_by_pk: res.data.data.reviews_by_pk
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.error(err);
      });
  };
  fetchReviewByUser = responseJson => {
    this.setState({ loading: true });
    let query = FETCH_REVIEWS_BY_USER;
    query.variables = {
      id: this.state.reviews_by_pk.user.id
    };
    

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log(res.data);

        this.setState({
          loading: false,
          reviews_by_user: res.data.data.users_by_pk.reviews,
          reviewsByUser: true
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.error(err);
      });
  };

  showReviewsByUser = e => {
    console.log(e);
    this.fetchReviewByUser();
  };
  approveOrRejectReview = status => {
    this.setState({ loading: true });
    let mutation = APPROVE_OR_REJECT_REVIEW;
    mutation.variables = {
      id: this.props.match.params.id,
      status: status
    };
    

    axios
      .post(API_ROOT, mutation)
      .then(res => {
        console.log("approveOrRejectReview", res.data);
        this.setState({ loading: false }, () => this.props.history.goBack());
      })
      .catch(err => {
        console.error(err);
      });
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchReviewByPK();
  }
  render() {
    const { loading, reviews_by_pk: review } = this.state;
    const OutwardArrow = () => (
      <svg
        width="8"
        height="7"
        viewBox="0 0 8 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 6.5L7 0.5M7 0.5H3M7 0.5V4" stroke="#585858" />
      </svg>
    );
    const MailToIcon = props => <Icon component={OutwardArrow} {...props} />;
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/reviews">
                      Reviews
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    Manage Reviews
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h3 style={{ margin: '1rem 0 0 0' }}>Manage Reviews</h3>
              </div>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Card>
                <Skeleton loading={loading} avatar active>
                  <Row type="flex" justify="center">
                    <Col
                      span={6}
                      offset={1}
                      style={{
                        textAlign: "left"
                      }}
                    >
                      {review.hasOwnProperty("user") && (
                        <React.Fragment>
                          {review.user.avatar ? (
                            <img
                              src={review.user.avatar}
                              alt={review.user.username}
                              height="125px"
                              width="125px"
                              style={{ objectFit: 'cover', borderRadius: '50%' }}
                            />
                          ) : (
                            <Avatar size={125} icon="user" />
                          )}
                        </React.Fragment>
                      )}
                    </Col>
                    <Col
                      span={17}
                      style={{
                        textAlign: "left",
                        marginTop: "1rem"
                      }}
                    >
                      <React.Fragment>
                        {review.hasOwnProperty("user") && (
                          <React.Fragment>
                            {review.user.username !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Col span={12}>
                                  <Text type="secondary">User</Text>
                                  <Paragraph>{review.user.username}</Paragraph>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>
                                {review.hasOwnProperty("user") && (
                                  <React.Fragment>
                                    {review.user.reviews_aggregate.aggregate.count > 0 && (
                                      <Button type="link" onClick={this.showReviewsByUser} style={{ color: '#6ECA4B' }}>
                                        {review.user.reviews_aggregate.aggregate.count === 1
                                          ? `View 1 other review by this user`
                                          : `View ${review.user.reviews_aggregate.aggregate.count} other reviews by this user`}
                                      </Button>
                                    )}
                                  </React.Fragment>
                                )}
                                </Col>
                              </Row>
                            )}
                            {review.user.email !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Text type="secondary">Email</Text>
                                <Paragraph>
                                  {review.user.email}
                                  <span className="outwardArrow">
                                    <a href={`mailto:${review.user.email}`}>
                                      <Icon type="arrow-up" />
                                    </a>
                                  </span>
                                </Paragraph>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      <React.Fragment>
                        {review.hasOwnProperty("monument") && (
                          <React.Fragment>
                            {review.monument.details !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Text type="secondary">Monument</Text>
                                <Paragraph>{review.monument.details}</Paragraph>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      <React.Fragment>
                        {review.hasOwnProperty("rating") && (
                          <React.Fragment>
                            {review.rating !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Text type="secondary">Rating</Text>
                                <p>
                                  <Rate disabled defaultValue={review.rating} />
                                </p>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      <React.Fragment>
                        {review.hasOwnProperty("comment") && (
                          <React.Fragment>
                            {review.comment !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Text type="secondary">comment</Text>
                                <Paragraph style={{ whiteSpace: "pre-wrap" }}>{review.comment}</Paragraph>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      <React.Fragment>
                        {review.hasOwnProperty("created_at") && (
                          <React.Fragment>
                            {review.created_at !== null && (
                              <Row style={{ marginBottom: "1rem" }}>
                                <Text type="secondary">Date Added</Text>
                                <Paragraph>
                                  {moment(review.created_at).format(
                                    "Do MMM YYYY"
                                  )}
                                </Paragraph>
                              </Row>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </Col>
                  </Row>
                </Skeleton>
              </Card>
            </Col>
          </Row>
          <React.Fragment>
            {review.hasOwnProperty("status") && (
              <React.Fragment>
                {review.status === "pending" && (
                  <Row
                    type="flex"
                    style={{
                      marginTop: "1rem",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Button
                      type="danger"
                      style={{ marginRight: "1rem" }}
                      onClick={() => this.approveOrRejectReview("rejected")}
                    >
                      Reject
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.approveOrRejectReview("approved")}
                    >
                      Approve
                    </Button>
                  </Row>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
        <React.Fragment>
          <Modal
            width={800}
            footer={null}
            title="Other Reviews by this user"
            visible={this.state.reviewsByUser}
            onCancel={() => this.setState({ reviewsByUser: false })}
            destroyOnClose
          >
            <List
              dataSource={this.state.reviews_by_user}
              renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    title={item.monument.details}
                    description={item.comment}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <i>
                      Posted on: {moment(item.created_at).format("Do MMM YYYY")}
                    </i>
                    <Rate disabled defaultValue={review.rating} />
                  </div>
                </List.Item>
              )}
            />
          </Modal>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
