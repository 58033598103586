import React from "react";
import GoogleMapReact from "google-map-react";

const Mapsvg = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4.74805C6.47441 4.74805 5.98047 4.95195 5.60781 5.32461C5.42469 5.50721 5.27948 5.72421 5.18053 5.96314C5.08158 6.20206 5.03085 6.45819 5.03125 6.7168C5.03125 7.24238 5.23691 7.73633 5.60781 8.10898C5.79041 8.29211 6.00741 8.43732 6.24634 8.53627C6.48526 8.63522 6.7414 8.68595 7 8.68555C7.52559 8.68555 8.01953 8.47988 8.39219 8.10898C8.76484 7.73633 8.96875 7.24238 8.96875 6.7168C8.96875 6.19121 8.76484 5.69727 8.39219 5.32461C8.01953 4.95195 7.52559 4.74805 7 4.74805ZM13.0223 4.08184C12.6913 3.31874 12.2142 2.62781 11.6178 2.04805C11.0194 1.46305 10.314 0.998735 9.54004 0.680469C8.73496 0.346484 7.88066 0.177734 7 0.177734C6.11934 0.177734 5.26504 0.346484 4.45996 0.678711C3.68125 1.00039 2.9834 1.46094 2.38223 2.04629C1.78619 2.62638 1.30911 3.31722 0.977734 4.08008C0.634961 4.87109 0.460938 5.71133 0.460938 6.57617C0.460938 7.81719 0.758008 9.05293 1.3416 10.2447C1.81094 11.2027 2.4666 12.1361 3.29277 13.0238C4.7043 14.5391 6.18437 15.4654 6.60449 15.7133C6.72374 15.7837 6.85975 15.8207 6.99824 15.8205C7.13535 15.8205 7.2707 15.7854 7.39199 15.7133C7.81211 15.4654 9.29219 14.5391 10.7037 13.0238C11.5299 12.1379 12.1855 11.2027 12.6549 10.2447C13.242 9.05469 13.5391 7.8207 13.5391 6.57793C13.5391 5.71309 13.365 4.87285 13.0223 4.08184ZM7 9.81055C5.29141 9.81055 3.90625 8.42539 3.90625 6.7168C3.90625 5.0082 5.29141 3.62305 7 3.62305C8.70859 3.62305 10.0938 5.0082 10.0938 6.7168C10.0938 8.42539 8.70859 9.81055 7 9.81055Z"
      fill="#F52A2A"
    />
  </svg>
);

const AnyReactComponent = ({ text, markerVisibility }) => (
  <div
    style={{
      color: "white",
      padding: "15px 10px",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      display: markerVisibility ? "inline-flex" : "none"
    }}
  >
    <Mapsvg />
  </div>
);

export default class SimpleMap extends React.Component {
  static defaultProps = {
    //  center: { lat: -18.0442547, lng: 178.5595941 },
    zoom: 11
  };
  //  state = {
  //    lat: -18.0442547,
  //    lng: 178.5595941
  //  };
  onClick = ({ x, y, lat, lng, event }) => this.props.onMarker(lat, lng);
  // onClick = ({x, y, lat, lng, event}) => console.log({lat, lng})

  componentDidMount() {
    console.log("mapProps", this.props);
  }
  render() {
    return (
      <React.Fragment>
        <GoogleMapReact
          style={{
            width: "100%",
            height: 170,
            position: "relative"
          }}
          bootstrapURLKeys={{
            key: "AIzaSyCibgSv-9Bnj-0nXMmEZTY_DlKal5jWeJk"
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            //  console.log("aaa", map, maps);
            //  this.placesService  = new maps.places.PlacesService(map);
          }}
          center={[this.props.lat, this.props.lng]}
          defaultZoom={18}
          onClick={this.onClick}
        >
          <AnyReactComponent
            lat={this.props.lat}
            lng={this.props.lng}
            text={"Monument"}
            markerVisibility={this.props.markerVisibility}
          />
        </GoogleMapReact>
      </React.Fragment>
    );
  }
}
