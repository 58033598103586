import React, { Component } from "react";
import Header from "./header";
import { Button, Row, Col, Table, Input, Icon } from "antd";
import {FETCH_ALL_MONUMENTS_V2} from "./graphql";
import { API_ROOT } from "./util";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import isEmpty from "lodash/isEmpty";

export default class AllMonuments extends Component {
  state = {
    loading: false,
    monuments: [],
    sortBy: "",
    filteredInfo: null,
    searchText: "",
    pagination: {
      showSizeChanger: false,
      pageSize: 10,
      current: 1,
      total: null,
      simple: false,
      defaultPageSize: 10
    }
  };

  sortByLogic = () => {
    switch (this.state.sortBy) {
      case "name_ascend":
        return { name: "asc" };
      case "name_descend":
        return { name: "desc" };
      case "updated_at_ascend":
        return { updated_at: "asc" };
      default:
        return { updated_at: "desc" };
    }
  };

  filterLogic = () => {
    if (this.state.filteredInfo != null) {
      let array_of_status = this.state.filteredInfo.status;

      if (array_of_status !== null) {
        console.log("ARRAY OF STATUS EXISTS", array_of_status);
        if (
          array_of_status.includes("Draft") &&
          !array_of_status.includes("Grey") &&
          !array_of_status.includes("Published")
        ) {
          console.log("Draft");
          return {
            status: { _eq: false },
            is_active: { _eq: true },
            name: { _ilike: `%${this.state.searchText}%` }
          };
        } else if (
          !array_of_status.includes("Draft") &&
          array_of_status.includes("Grey") &&
          !array_of_status.includes("Published")
        ) {
          console.log("Grey");
          return {
            status: { _eq: true },
            details: { _contains: { mt_isActiveOnApp: "no" } },
            is_active: { _eq: true },
            name: { _ilike: `%${this.state.searchText}%` }
          };
        } else if (
          !array_of_status.includes("Draft") &&
          !array_of_status.includes("Grey") &&
          array_of_status.includes("Published")
        ) {
          console.log("Published");
          return {
            status: { _eq: true },
            details: { _contains: { mt_isActiveOnApp: "yes" } },
            is_active: { _eq: true },
            name: { _ilike: `%${this.state.searchText}%` }
          };
        } else if (
          array_of_status.includes("Draft") &&
          array_of_status.includes("Grey") &&
          !array_of_status.includes("Published")
        ) {
          console.log("Draft, Grey");
          return {
            _and: [
              { is_active: { _eq: true } },
              {
                _or: [
                  { status: { _eq: false } },
                  {
                    _and: [
                      { details: { _contains: { mt_isActiveOnApp: "no" } } },
                      { status: { _eq: true } }
                    ]
                  }
                ]
              },
              {name: {_ilike: `%${this.state.searchText}%`}}
            ]
          };
        } else if (
          array_of_status.includes("Draft") &&
          !array_of_status.includes("Grey") &&
          array_of_status.includes("Published")
        ) {
          console.log("Draft, Published");
          return {
            _and: [
              { is_active: { _eq: true } },
              {
                _or: [
                  { status: { _eq: false } },
                  {
                    _and: [
                      { details: { _contains: { mt_isActiveOnApp: "yes" } } },
                      { status: { _eq: true } }
                    ]
                  }
                ]
              },
              {name: {_ilike: `%${this.state.searchText}%`}}
            ]
          };
        } else if (
          !array_of_status.includes("Draft") &&
          array_of_status.includes("Grey") &&
          array_of_status.includes("Published")
        ) {
          console.log("Grey, Published");
          return {
            status: { _eq: true },
            is_active: { _eq: true },
            name: { _ilike: `%${this.state.searchText}%` }
          };
        } else if (
          array_of_status.includes("Draft") &&
          array_of_status.includes("Grey") &&
          array_of_status.includes("Published")
        ) {
          console.log("Draft, Grey, Published");
          return {
            is_active: { _eq: true },
            name: { _ilike: `%${this.state.searchText}%` }
          };
        }
      }
    }
    else {
       return {
         is_active: { _eq: true },
         name: { _ilike: `%${this.state.searchText}%` }
       };
    }
  };

  fetchAllMonuments = () => {
    const { pagination } = this.state;
    let query = FETCH_ALL_MONUMENTS_V2;

    console.log(this.state.filteredInfo);

    query.variables = {
      order: this.sortByLogic(),
      // name: `%${this.state.searchText}%`,
      limit: this.state.pagination.pageSize,
      offset:
        pagination.current === 1
          ? 0
          : (pagination.current - 1) * pagination.pageSize,
      where: this.filterLogic()
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllMonuments", res.data);
        let stateCopy = { ...this.state };
        stateCopy.monuments = res.data.data.monuments;
        stateCopy.pagination.total =
          res.data.data.monuments_aggregate.aggregate.count;
        stateCopy.loading = false;
        console.log(stateCopy);
        this.setState(stateCopy);
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    if (!isEmpty(filters.status)) {
      await this.setState({ filteredInfo: filters });
    }

    if (!isEmpty(sorter)) {
      await this.setState({ sortBy: `${sorter.columnKey}_${sorter.order}` });
    }

    if (this.state.pagination.current !== pagination.current) {
      await this.setState({ pagination: pagination, filteredInfo: filters });
    }

    if (
      isEmpty(filters.status) &&
      this.state.pagination.current === pagination.current
    ) {
      this.setState({ filteredInfo: null }, () => {
        this.fetchAllMonuments();
      });
    }

    this.fetchAllMonuments();
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] }, () => {
      this.fetchAllMonuments();
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    console.log("RESET");
    this.setState({ searchText: "", filteredInfo: null }, () => {
      this.fetchAllMonuments();
    });
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchAllMonuments();
  }

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || [];

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 250,
        ...this.getColumnSearchProps("name"),
        sorter: true
      },
      {
        title: "Location",
        dataIndex: "details.mt_location",
        key: "location",
        width: 250
      },

      {
        title: "Era",
        dataIndex: "era",
        key: "era",
        width: 350,
        render: (text, record) => <span>{record.era.name}</span>
      },
      {
        title: "Last modified",
        dataIndex: "updated_at",
        key: "updated_at",
        width: 350,
        render: (text, record) => (
          <span>{moment(record.updated_at).format("Do MMM YYYY, h:mm a")}</span>
        ),
        sorter: true
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 350,
        render: (text, record) => (
          <span>
            {!record.status
              ? "Draft"
              : [
                  record.status && record.details.mt_isActiveOnApp === "no"
                    ? "Published (grey)"
                    : "Published"
                ]}
          </span>
        ),
        filters: [
          { text: "Draft", value: "Draft" },
          { text: "Published (grey)", value: "Grey" },
          { text: "Published", value: "Published" }
        ],
        filteredValue: filteredInfo.status || null
      },
      {
        title: "Action",
        key: "action",
        width: 250,
        render: (text, record) => (
          <span>
            <Link to={`/monuments/${record.id}`}>View</Link>
            {/* <Divider type="vertical" /> */}
            {/* <Link to={`/era/${record.id}`}>Delete</Link> */}
          </span>
        )
      }
    ];
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row type="flex" justify="space-between">
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              Monuments
            </Col>
            <Col
              span={4}
              style={{
                textAlign: "right"
              }}
            >
              <Button
                type="primary"
                onClick={() => this.props.history.push("/monuments/add")}
              >
                Add a Monument
              </Button>
            </Col>
          </Row>
          <Row type="flex">
            {this.state.monuments && (
              <React.Fragment>
                <Table
                  columns={columns}
                  dataSource={this.state.monuments}
                  onChange={this.handleTableChange}
                  pagination={this.state.pagination}
                  style={{
                    backgroundColor: "#fff",
                    margin: "auto"
                  }}
                />
              </React.Fragment>
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
