import React from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Upload,
  Select,
  Radio,
  Modal,
  Divider,
  TimePicker,
  AutoComplete,
  Col
} from "antd";
import moment from "moment";
import axios from "axios";
import debounce from "lodash";
import s3 from "./uploads/do.js";
import Config from "./uploads/config";
import { ADD_TAG, FETCH_ALL_ERA, GET_ALL_TAGS } from "./graphql";
import { API_ROOT, signAUrl, uploadFiles } from "./util";

class EditMonument extends React.Component {
  state = {
    previewVisible: false,
    fileList: [],
    era: [],
    fileList4gallery: [],
    predictions: [],
    tags: [],
    tag_ids: [],
    previewImage: "",
    numberType: "landline",
    address: "",
    lat: -18.0442547,
    lng: 178.5595941,
    monument_lat: -18.0442547,
    monumonet_lng: 178.5595941,
    markerVisibility: true
  };

  fetchTags = () => {
    let query = GET_ALL_TAGS;
    // let token =  localStorage.getItem('token')

    axios
      .post(API_ROOT, query)
      .then(res => {
        this.setState({ tags: res.data.data.tags });
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // console.log("Received errors of form: ", err);
      if (!err) {
        this.props.onAddEra(values);
      }
    });
  };
  normFile = e => {
    // console.log("Upload event:", e);
    console.log("s3:", s3);
    // const blob = new Blob(e.fileList[0]);
    const blob = JSON.stringify(e.file);
    console.log("Upload event:", e.file);

    const s3Params = {
      Body: e.file,
      Bucket: "monuments",
      Expires: 600,
      Key: e.file.name,
      ContentType: "image/png", // "image/jpeg"
      ACL: "public-read"
    };
    s3.putObject(s3Params)
      .on("build", request => {
        request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`;
        request.httpRequest.headers["Content-Length"] = e.file.size;
        request.httpRequest.headers["Content-Type"] = e.file.type;
        request.httpRequest.headers["x-amz-acl"] = "public-read";
      })
      .send(err => {
        if (err) {
          console.log("err", err);
        } else {
          // If there is no error updating the editor with the imageUrl
          const imageUrl = `${Config.digitalOceanSpaces}` + blob.name;
          console.log("imageUrl", imageUrl);
        }
      });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  onUpload = info => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    this.setState({ fileList });
  };

  onSearchSelect = (input, option) => {
    return option.props.children
      .toLowerCase()
      .trim()
      .includes(input.toLowerCase().trim());
  };

  handleTagChange = value => {
    let tag_ids = [];
    value.forEach(v => {
      let filter = this.state.tags.filter(tag => tag.name === v);
      if (filter.length) {
        tag_ids.push(filter[0].id);
        this.setState({ tag_ids: tag_ids });
      } else {
        let mutation = ADD_TAG;
        mutation.variables = { objects: { name: v } };
        axios
          .post(API_ROOT, mutation)
          .then(res => {
            tag_ids.push(res.data.data.insert_tags.returning[0].id);
            this.setState({ tag_ids: tag_ids });
            this.fetchTags();
          })
          .catch(err => console.log(err));
      }
    });
  };

  handleNumberTypeChange = e => {
    let numberType = e.target.value;
    this.props.form.setFieldsValue({ mt_numberType: numberType });
  };

  //gallery upload methods start
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  onSelect = (value, option) => {
    console.log(value, option.props.children);
    this.setState(
      {
        markerVisibility: true,
        address: option.props.children
      },
      () => this.getCoordinates(value)
    );

    //  debounce(this.getPredictions(search.target.value), 150);
  };

  onSearch = search => {
    // console.log(search)

    this.setState(
      {
        address: search
      },
      () => debounce(this.getPredictions(search), 1600)
    );
  };

  getPredictions = searchText => {
    const url = `https://jovial-ptolemy-be0f94.netlify.app/.netlify/functions/getpredictions?search=${searchText}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => this.setState({ predictions: res.data }));
  };

  getCoordinates = placeId => {
    const url = `https://jovial-ptolemy-be0f94.netlify.app/.netlify/functions/getcoordinates?placeId=${placeId}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => this.setState({ lat: res.lat, lng: res.lng }));
  };

  getAddressFromCoordinates = (lat, lng) => {
    const url = `https://jovial-ptolemy-be0f94.netlify.app/.netlify/functions/getaddress?lat=${lat}&lng=${lng}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => this.setState({ address: res.address }));
  };

  onMarker = (lat, lng) => {
    if (this.state.predictions.length === 0) {
      this.getAddressFromCoordinates(lat, lng);
      this.setState({ markerVisibility: true });
    }
    this.setState({
      lat: lat,
      lng: lng
    });
  };

  //gallery upload methods end

  customRequest = async ({ file, onSuccess }) => {
    const signedUploadUrl = await signAUrl(`${file.name}`, `${file.type}`);
    const filesArray = [file];
    const signedS3UrlArray = [signedUploadUrl];
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    onSuccess(uploadedResponse, file);
  };

  fetchAllEra = async responseJson => {
    let query = FETCH_ALL_ERA;

    await axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          era: res.data.data.era,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  onFileChange = ({ file, fileList }) => {
    this.setState({
      fileList: fileList
    });
    this.props.form.setFieldsValue({
      mt_heroImg: fileList.length ? fileList[0].response : ""
    });
  };

  onGalleryChange = ({ file, fileList }) => {
    this.setState({
      fileList4gallery: fileList
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.data.details.mt_heroImg) !==
      JSON.stringify(this.props.data.details.mt_heroImg)
    ) {
      this.setState({
        fileList:
          this.props.data.details.mt_heroImg.length > 0
            ? [
                {
                  uid: "logo",
                  name: "hgfghgc",
                  response: this.props.data.details.mt_heroImg[0],
                  url: this.props.data.details.mt_heroImg[0]
                }
              ]
            : []
      });
      this.props.form.setFieldsValue({
        mt_heroImg: this.props.data.details.mt_heroImg
      });
    }

    if (
      JSON.stringify(prevProps.data.monuments_x_tags) !==
      JSON.stringify(this.props.data.monuments_x_tags)
    ) {
      this.setState({
        tag_ids: this.props.data.monuments_x_tags.map(tag => tag.id)
      });
    }

    if (
      JSON.stringify(prevProps.data.details.mt_gallery) !==
      JSON.stringify(this.props.data.details.mt_gallery)
    ) {
      this.setState({
        fileList4gallery:
          this.props.data.details.mt_gallery.length > 0
            ? this.props.data.details.mt_gallery.map((item, i) => ({
                uid: i,
                name: "ttt",
                response: item,
                url: item
              }))
            : []
      });
      this.props.form.setFieldsValue({
        mt_gallery: this.props.data.details.mt_gallery
      });
    }
    if (
      JSON.stringify(prevProps.data.details.mt_mapLocation) !==
      JSON.stringify(this.props.data.details.mt_mapLocation)
    ) {
      this.setLocation();
    }
  }

  setLocation = () => {
    // if (this.props.data.details.mt_mapLocation) {
    //   const url = `https://jovial-ptolemy-be0f94.netlify.com/.netlify/functions/getcoordinates?placeId=${this.props.data.details.mt_mapLocation}`;
    //   axios.post(url).then(res =>
    //     this.setState({
    //       lat: res.data.lat,
    //       lng: res.data.lng
    //     }, () => this.getAddressFromCoordinates(res.data.lat, res.data.lng))
    //   );
    // }
    console.log("this.props.data.airport", this.props.data.airport);
    this.setState({
      monument_lat: this.props.data.details.mt_lat,
      monument_lng: this.props.data.details.mt_long,
      address: this.props.data.airport ? this.props.data.airport.name : null,
      lat: this.props.data.airport
        ? this.props.data.airport.location.coordinates[0]
        : null,
      lng: this.props.data.airport
        ? this.props.data.airport.location.coordinates[1]
        : null
      // },
      // () =>
      //   this.getAddressFromCoordinates(
      //     this.props.data.location.coordinates[0],
      //     this.props.data.location.coordinates[1]
      //   )
    });
    console.log(this.props.data.location.coordinates);
  };

  componentDidMount() {
    this.fetchAllEra();
    this.setLocation();
    this.fetchTags();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { details, location, era_id, monuments_x_tags } = this.props.data;
    const Mapsvg = () => (
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 4.74805C6.47441 4.74805 5.98047 4.95195 5.60781 5.32461C5.42469 5.50721 5.27948 5.72421 5.18053 5.96314C5.08158 6.20206 5.03085 6.45819 5.03125 6.7168C5.03125 7.24238 5.23691 7.73633 5.60781 8.10898C5.79041 8.29211 6.00741 8.43732 6.24634 8.53627C6.48526 8.63522 6.7414 8.68595 7 8.68555C7.52559 8.68555 8.01953 8.47988 8.39219 8.10898C8.76484 7.73633 8.96875 7.24238 8.96875 6.7168C8.96875 6.19121 8.76484 5.69727 8.39219 5.32461C8.01953 4.95195 7.52559 4.74805 7 4.74805ZM13.0223 4.08184C12.6913 3.31874 12.2142 2.62781 11.6178 2.04805C11.0194 1.46305 10.314 0.998735 9.54004 0.680469C8.73496 0.346484 7.88066 0.177734 7 0.177734C6.11934 0.177734 5.26504 0.346484 4.45996 0.678711C3.68125 1.00039 2.9834 1.46094 2.38223 2.04629C1.78619 2.62638 1.30911 3.31722 0.977734 4.08008C0.634961 4.87109 0.460938 5.71133 0.460938 6.57617C0.460938 7.81719 0.758008 9.05293 1.3416 10.2447C1.81094 11.2027 2.4666 12.1361 3.29277 13.0238C4.7043 14.5391 6.18437 15.4654 6.60449 15.7133C6.72374 15.7837 6.85975 15.8207 6.99824 15.8205C7.13535 15.8205 7.2707 15.7854 7.39199 15.7133C7.81211 15.4654 9.29219 14.5391 10.7037 13.0238C11.5299 12.1379 12.1855 11.2027 12.6549 10.2447C13.242 9.05469 13.5391 7.8207 13.5391 6.57793C13.5391 5.71309 13.365 4.87285 13.0223 4.08184ZM7 9.81055C5.29141 9.81055 3.90625 8.42539 3.90625 6.7168C3.90625 5.0082 5.29141 3.62305 7 3.62305C8.70859 3.62305 10.0938 5.0082 10.0938 6.7168C10.0938 8.42539 8.70859 9.81055 7 9.81055Z"
          fill="#C6C6C6"
        />
      </svg>
    );
    const MapPin = props => <Icon component={Mapsvg} {...props} />;
    const options = this.state.predictions.map(pred => (
      <AutoComplete.Option key={pred.place_id}>
        {pred.description}
      </AutoComplete.Option>
    ));
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="add-monument-form"
        style={{
          textAlign: "left"
        }}
        hideRequiredMark
        layout="vertical"
      >
        <Form.Item label="Monument Name">
          {getFieldDecorator("mt_name", {
            rules: [
              {
                required: true,
                message: "Please enter a name for this monument!"
              }
            ],
            initialValue: details.mt_name
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Location">
          {getFieldDecorator("mt_location", {
            rules: [
              {
                required: true,
                message: "Please enter a location for this era!"
              }
            ],
            initialValue: details.mt_location
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Era">
          {getFieldDecorator("mt_era", {
            rules: [{ required: true, message: "Please select an era!" }],
            initialValue: era_id
          })(
            <Select placeholder="Select an era">
              {this.state.era.map((era, index) => {
                return <Select.Option value={era.id}>{era.name}</Select.Option>;
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Monument Hero image">
          {getFieldDecorator("mt_heroImg", {
            rules: [{ required: true, message: "Please upload header img" }]
          })(
            <Upload
              name="logo"
              defaultFileList={this.state.fileList}
              fileList={this.state.fileList}
              listType="picture"
              customRequest={this.customRequest}
              onChange={this.onFileChange}
              accept=".jpg, .jpeg, .png"
            >
              <Button disabled={this.state.fileList.length > 0}>
                <Icon type="upload" /> Upload Hero image
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item
          label="Is Monument Active in app?"
          extra="Selecting NO will show the monument in greyed-out mode and will not be tappable"
          style={{ display: "none" }}
        >
          {getFieldDecorator("mt_isActiveOnApp", {
            initialValue: "yes"
          })(
            <Radio.Group>
              <Radio value="no">No</Radio>
              <Radio value="yes">Yes</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label="Video link">
          {getFieldDecorator("mt_video", {
            rules: [{ required: true, message: "Please enter a video link!" }],
            initialValue: details.mt_video
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Monument Description">
          {getFieldDecorator("mt_description", {
            rules: [{ required: true, message: "Please enter description" }],
            initialValue: details.mt_description
          })(<Input.TextArea rows={4} />)}
        </Form.Item>
        <Form.Item label="Gallery">
          {getFieldDecorator("mt_gallery", {
            rules: [{ required: false, message: "Please at least one image" }]
          })(
            <React.Fragment>
              <Upload
                name="logo"
                customRequest={this.customRequest}
                defaultFileList={this.state.fileList4gallery}
                fileList={this.state.fileList4gallery}
                onChange={this.onGalleryChange}
                onPreview={this.handlePreview}
                listType="picture-card"
                multiple={true}
                accept=".jpg, .jpeg, .png"
              >
                <div>
                  <Icon type="plus" />
                  <div className="ant-upload-text">Upload</div>
                </div>
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </Modal>
            </React.Fragment>
          )}
        </Form.Item>
        <Form.Item label="Tags">
          {getFieldDecorator("tags", {
            rules: [{ required: false, message: "Please select a tag" }],
            initialValue: monuments_x_tags
              ? monuments_x_tags.map(tag => tag.tag.name)
              : []
          })(
            <Select
              placeholder="Select Tags"
              mode="tags"
              tokenSeparators={[","]}
              onChange={this.handleTagChange}
              filterOption={this.onSearchSelect}
            >
              {this.state.tags.map((tag, i) => (
                <Select.Option key={i} value={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Divider type="horizontal" />
        <div>
          <p> Map location </p>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              margin: "6px 16px"
            }}
          >
            <Col span={12} style={{ marginRight: "16px" }}>
              <Form.Item label="Latitude">
                {getFieldDecorator("mt_lat", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter the latitude for this monument!"
                    }
                  ],
                  initialValue: details.mt_lat
                })(<Input placeholder="Eg: 7.234" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Longitude">
                {getFieldDecorator("mt_long", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter the longitude for this monument!"
                    }
                  ],
                  initialValue: details.mt_long
                })(<Input placeholder="Eg: 12.234" />)}
              </Form.Item>
            </Col>
          </div>
        </div>
        <Form.Item label="Nearest Airport">
          {getFieldDecorator("mt_mapLocation", {
            rules: [{ required: true, message: "Please enter place" }],
            initialValue: this.state.address
          })(
            <AutoComplete
              className="hideSuffix"
              style={{ width: "100%" }}
              onSelect={this.onSelect}
              onSearch={e => this.onSearch(e)}
              placeholder="Search for place here"
              dataSource={options}
              allowClear
            >
              {/* {this.state.predictions.map(pred => (
              <AutoComplete.Option key={pred.place_id}>
                {pred.description}
              </AutoComplete.Option>
            ))} */}
              <Input suffix={<MapPin />} />
            </AutoComplete>
          )}
        </Form.Item>
        <div>
          <span style={{ marginBottom: "6px" }}>Open Hours</span>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start"
            }}
          >
            <Form.Item>
              <span style={{ margin: "6px 16px" }}>From</span>
              {getFieldDecorator("mt_openFrom", {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: "Please select time!"
                  }
                ],
                initialValue:
                  details.mt_openFrom !== "Invalid date" &&
                  details.mt_openFrom != undefined
                    ? moment(details.mt_openFrom, "HH:mm")
                    : null
              })(<TimePicker format="HH:mm" minuteStep={15} />)}
            </Form.Item>
            <span style={{ margin: "6px 16px" }}>To</span>
            <Form.Item>
              {getFieldDecorator("mt_openTill", {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: "Please select time!"
                  }
                ],
                initialValue:
                  details.mt_openTill !== "Invalid date" &&
                  details.mt_openTill != undefined
                    ? moment(details.mt_openTill, "HH:mm")
                    : null
              })(<TimePicker format="HH:mm" minuteStep={15} />)}
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Additional Text (optional)"
          extra="This text will be displayed as a pop-up in the app."
        >
          {getFieldDecorator("mt_additionalText", {
            rules: [{ required: false, message: "Please enter description" }],
            initialValue: details.mt_additionalText
          })(<Input.TextArea rows={2} />)}
        </Form.Item>
        <Form.Item label="Number Type">
          {getFieldDecorator("mt_numberType", {
            initialValue:
              details.mt_numberType === null
                ? this.state.numberType
                : details.mt_numberType
          })(
            <Radio.Group onChange={this.handleNumberTypeChange}>
              <Radio value="landline">landline</Radio>
              <Radio value="mobile">mobile</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        {this.props.form.getFieldsValue(["mt_numberType"]).mt_numberType ===
        "landline" ? (
          <Form.Item label="Contact Number">
            <Input.Group compact>
              <Input style={{ width: "20%" }} defaultValue="+91" disabled />
              {getFieldDecorator("mt_phone", {
                rules: [
                  { required: true, message: "Please enter phone number" }
                ],
                initialValue: details.mt_phone
              })(<Input style={{ width: "20%" }} />)}
              {getFieldDecorator("mt_phoneNumber", {
                rules: [{ required: false, message: "" }],
                initialValue: details.mt_phoneNumber
              })(<Input style={{ width: "30%" }} />)}
            </Input.Group>
          </Form.Item>
        ) : (
          <Form.Item label="Contact Number">
            <Input.Group compact>
              <Input style={{ width: "20%" }} defaultValue="+91" disabled />
              {getFieldDecorator("mt_phoneNumber", {
                rules: [
                  { required: true, message: "Please enter phone number" }
                ],
                initialValue: details.mt_phoneNumber
              })(<Input style={{ width: "30%" }} />)}
            </Input.Group>
          </Form.Item>
        )}
        {/* <AutoComplete
          value={this.state.address}
          style={{ width: 200, marginBottom: "2rem" }}
          onSelect={this.onSelect}
          onSearch={e => this.onSearch(e)}
          placeholder="Search for place here"
        >
          {this.state.predictions.map(pred => (
            <AutoComplete.Option key={pred.place_id}>
              {pred.description}
            </AutoComplete.Option>
          ))}
        </AutoComplete>

         <SimpleMap
          style={{
            width: 400
          }}
          lat={this.state.lat}
          lng={this.state.lng}
          onMarker={this.onMarker}
          markerVisibility={this.state.markerVisibility}
        /> */}

        {/* <Form.Item label="Map">
          <SimpleMap
            lat={this.state.lat}
            lng={this.state.lng}
            onMarker={this.onMarker}
            markerVisibility={this.state.markerVisibility}
          />
        </Form.Item> */}
      </Form>
    );
  }
}

export const WrappedEditMonument = Form.create({ name: "edit_monument" })(
  EditMonument
);
