import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import {
  FETCH_ALL_REVIEWS,
  FETCH_ALL_APPROVED_REVIEWS_GROUPED_BY_MONUMENTS,
  APPROVE_OR_REJECT_REVIEW
} from "./graphql";
import {
  Tabs,
  Row,
  Col,
  Badge,
  Table,
  Button,
  Input,
  Icon,
  Divider,
  Typography
} from "antd";
import Highlighter from "react-highlight-words";
import { API_ROOT } from "./util";
import axios from "axios";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
const { TabPane } = Tabs;
const { Paragraph } = Typography;

function callback(key) {
  console.log(key);
}
export default class AllReviews extends Component {
  state = {
    loading: false,
    status: "pending",
    pagination: {
      showSizeChanger: false,
      pageSize: 10,
      current: 1,
      total: null,
      simple: false,
      defaultPageSize: 10
    },
    reviews: [],
    monuments: [],
    sortBy: "",
    searchText: {}
  };

  fetchAllReviews = responseJson => {
    // responseJson.value
    // responseJson.index
    console.log(responseJson);

    const { pagination } = this.state;
    let query = FETCH_ALL_REVIEWS;
    query.variables = {
      status: this.state.status,
      limit: this.state.pagination.pageSize,
      offset:
        pagination.current === 1
          ? 0
          : (pagination.current - 1) * pagination.pageSize,
      order: this.sortByLogic(),
      searchByName:
        this.state.searchText.index != null
          ? this.state.searchText.index === "name"
            ? `%${this.state.searchText.value[0]}%`
            : null
          : null,
      searchByUserName:
        this.state.searchText.index != null
          ? this.state.searchText.index === "username"
            ? `%${this.state.searchText.value[0]}%`
            : null
          : null
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllReviews", res.data);
        let stateCopy = { ...this.state };
        stateCopy.reviews = res.data.data.reviews;
        stateCopy.pagination.total =
          res.data.data.reviews_aggregate.aggregate.count;
        stateCopy.loading = false;
        this.setState(stateCopy);
      })
      .catch(err => {
        console.error(err);
      });
  };

  fetchAllReviewsGrpMonu = responseJson => {
    const { pagination } = this.state;
    let query = FETCH_ALL_APPROVED_REVIEWS_GROUPED_BY_MONUMENTS;

    query.variables = {
      // limit: this.state.pagination.pageSize,
      // offset:
      //   pagination.current === 1
      //     ? 0
      //     : (pagination.current - 1) * pagination.pageSize,
      order: this.sortByLogic(),
      searchByName:
        this.state.searchText.index != null
          ? `%${this.state.searchText.value[0]}%`
          : null
    };

    console.log(query.variables);

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllReviewsGrpMonu", res.data);
        let stateCopy = { ...this.state };
        stateCopy.monuments = res.data.data.monuments.filter(
          mon => mon.reviews.length !== 0
        );
        stateCopy.pagination.total = stateCopy.monuments.length;
        stateCopy.loading = false;
        this.setState(stateCopy);
      })
      .catch(err => {
        console.error(err);
      });
  };
  approveOrRejectReview = (id, status) => {
    console.log(id, status);
    this.setState({ loading: true });
    let mutation = APPROVE_OR_REJECT_REVIEW;
    mutation.variables = {
      id: id,
      status: status
    };

    axios
      .post(API_ROOT, mutation)
      .then(res => {
        console.log("approveOrRejectReview", res.data);
        this.setState({ loading: false }, () => this.fetchAllReviews());
      })
      .catch(err => {
        console.error(err);
      });
  };

  sortByLogic = () => {
    switch (this.state.sortBy) {
      case "name_ascend":
        return { monument: { name: "asc" } };
        break;
      case "name_descend":
        return { monument: { name: "desc" } };
        break;
      case "rating_ascend":
        return { rating: "asc" };
        break;
      case "rating_descend":
        return { rating: "desc" };
        break;
      case "created_at_ascend":
        return { created_at: "asc" };
        break;
      case "created_at_descend":
        return { created_at: "desc" };
        break;
      case "approved_name_ascend":
        return { name: "asc" };
        break;
      case "approved_name_descend":
        return { name: "desc" };
        break;
      case "approved_avg_ascend":
        return { reviews_aggregate: { avg: { rating: "asc" } } };
        break;
      case "approved_avg_descend":
        return { reviews_aggregate: { avg: { rating: "desc" } } };
        break;
      default:
        return { created_at: "desc" };
        break;
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.status === "approved" && isEmpty(sorter)) {
      this.setState({ pagination: pagination }, () => {
        console.log("Calling fetchAllReviewsGrpMonu");
        this.fetchAllReviewsGrpMonu();
      });
    }

    if (this.state.status !== "approved" && isEmpty(sorter)) {
      this.setState({ pagination: pagination }, () => {
        console.log("Calling fetchAllReviews");
        this.fetchAllReviews();
      });
    }
    // else if (isEmpty(filters)) {
    //   this.setState({ pagination: pagination }, () => this.fetchAllReviews());
    // }

    console.log(sorter);

    if (!isEmpty(sorter) && this.state.status != "approved") {
      this.setState({ sortBy: `${sorter.columnKey}_${sorter.order}` }, () =>
        this.fetchAllReviews()
      );
    } else if (!isEmpty(sorter) && this.state.status == "approved") {
      this.setState(
        { sortBy: `approved_${sorter.columnKey}_${sorter.order}` },
        () => {
          console.log("Calling fetchAllReviewsGrpMonu");
          this.fetchAllReviewsGrpMonu();
        }
      );
    }
  };

  onTabSwitch = e => {
    let stateCopy = { ...this.state };
    stateCopy.status = e;
    stateCopy.searchText = "";
    stateCopy.pagination.current = 1;
    console.log(e);
    if (e === "approved") {
      this.setState(stateCopy, () => this.fetchAllReviewsGrpMonu());
    } else {
      this.setState(stateCopy, () => this.fetchAllReviews());
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys.value ? selectedKeys.value[0] : null}
          onChange={e =>
            setSelectedKeys(
              e.target.value
                ? { value: [e.target.value], index: dataIndex }
                : { value: [], index: null }
            )
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={
          this.state.searchText.value != null
            ? [this.state.searchText.value[0]]
            : [""]
        }
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    console.log(selectedKeys);
    // console.log("calling API");
    if (this.state.status === "approved") {
      this.setState({ searchText: selectedKeys }, () =>
        this.fetchAllReviewsGrpMonu()
      );
      // console.log(selectedKeys)
    } else {
      this.setState({ searchText: selectedKeys }, () => this.fetchAllReviews());
    }
  };

  handleReset = clearFilters => {
    // clearFilters();
    this.setState({ searchText: "" }, () => {
      if (this.state.status === "approved") {
        this.fetchAllReviewsGrpMonu();
      } else {
        this.fetchAllReviews();
      }
    });
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchAllReviews();
  }
  render() {
    const columnsPending = [
      {
        title: "Monument",
        dataIndex: "monument.name",
        key: "name",
        width: 200,
        sorter: true,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            console.log("onRow", record);
            this.props.history.push(`/reviews/${record.id}`);
          }
        }),
        ...this.getColumnSearchProps("name")
      },
      {
        title: "User",
        dataIndex: "user.username",
        key: "username",
        width: 200,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            console.log("onRow", record);
            this.props.history.push(`/reviews/${record.id}`);
          }
        }),
        ...this.getColumnSearchProps("username")
      },
      {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
        width: 150,
        sorter: true,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            console.log("onRow", record);
            this.props.history.push(`/reviews/${record.id}`);
          }
        })
      },
      {
        title: "Review",
        dataIndex: "comment",
        key: "comment",
        width: 200,
        render: (text, record) => (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true
            }}
          >
            {text}
          </Paragraph>
        ),
        onCell: (record, rowIndex) => ({
          onClick: () => {
            console.log("onRow", record);
            this.props.history.push(`/reviews/${record.id}`);
          }
        })
      },
      {
        title: "Updated On",
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        render: (text, record) => (
          <span>{moment(record.created_at).format("Do MMM YYYY")}</span>
        ),
        sorter: true,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            console.log("onRow", record);
            this.props.history.push(`/reviews/${record.id}`);
          }
        })
      },
      {
        title: "Action",
        key: "action",
        width: 200,
        render: (text, record) => (
          <span>
            <Button
              onClick={() => this.approveOrRejectReview(record.id, "approved")}
              type="link"
            >
              Approve
            </Button>
            <Divider type="vertical" />
            <Button
              onClick={() => this.approveOrRejectReview(record.id, "rejected")}
              type="link"
              style={{ color: "red" }}
            >
              Reject
            </Button>
          </span>
        )
      }
    ];
    const columnsApproved = [
      {
        title: "Monument",
        dataIndex: "name",
        key: "name",
        width: 250,
        sorter: true,
        ...this.getColumnSearchProps("name")
      },
      {
        title: "No. Of Reviews",
        dataIndex: "reviews_aggregate.aggregate.count",
        key: "count",
        width: 250
      },
      {
        title: "Average Rating",
        dataIndex: "reviews_aggregate.aggregate.avg.rating",
        key: "avg",
        width: 250,
        sorter: true,
        render: (text, record) => <span>{text.toFixed(1)}</span>
      },
      {
        title: "Action",
        key: "action",
        width: 250,
        render: (text, record) => (
          <span>
            <Link to={`/reviews/monument/${record.id}`}>Manage</Link>
          </span>
        )
      }
    ];
    const columnsRejected = [
      {
        title: "Monument",
        dataIndex: "monument.name",
        key: "name",
        width: 250,
        sorter: true,
        ...this.getColumnSearchProps("name")
      },
      {
        title: "User",
        dataIndex: "user.username",
        key: "username",
        width: 250,
        ...this.getColumnSearchProps("username")
      },
      {
        title: "Email",
        dataIndex: "user.email",
        key: "username",
        width: 250
      },
      {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
        width: 100,
        sorter: true
      },
      {
        title: "Review",
        dataIndex: "comment",
        key: "comment",
        width: 400,
        render: (text, record) => (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true
            }}
          >
            {text}
          </Paragraph>
        )
      },
      {
        title: "Updated On",
        dataIndex: "created_at",
        key: "created_at",
        width: 350,
        sorter: true,
        render: (text, record) => (
          <span>{moment(record.created_at).format("Do MMM YYYY")}</span>
        )
      }
    ];
    return (
      <React.Fragment>
        <Header />
        <div
          style={{
            margin: "2rem 6rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              Reviews
            </Col>
          </Row>
          <Row type="flex">
            <Tabs
              defaultActiveKey="pending"
              onChange={this.onTabSwitch}
              style={{ textAlign: "left", width: "100%" }}
            >
              <TabPane
                tab={
                  <div>
                    Pending
                    <Badge
                      showZero
                      style={{ backgroundColor: "#999999" }}
                      count={this.state.pagination.total}
                    />
                  </div>
                }
                key="pending"
              >
                {this.state.reviews && (
                  <React.Fragment>
                    <Table
                      columns={columnsPending}
                      dataSource={this.state.reviews}
                      pagination={this.state.pagination}
                      style={{
                        backgroundColor: "#fff",
                        margin: "auto"
                      }}
                      onChange={this.handleTableChange}
                    />
                  </React.Fragment>
                )}
              </TabPane>
              <TabPane
                tab={
                  <div>
                    Approved
                    <Badge
                      showZero
                      style={{ backgroundColor: "#999999" }}
                      count={
                        this.state.monuments.length != 0
                          ? this.state.monuments.reduce((agg, monument) => {
                              return (
                                agg + monument.reviews_aggregate.aggregate.count
                              );
                            }, 0)
                          : this.state.monuments.length
                      }
                    />
                  </div>
                }
                key="approved"
              >
                {this.state.reviews && (
                  <React.Fragment>
                    <Table
                      onRow={(record, rowIndex) => ({
                        onClick: () => {
                          console.log("onRow", record);
                          this.props.history.push(
                            `/reviews/monument/${record.id}`
                          );
                        }
                      })}
                      columns={columnsApproved}
                      dataSource={this.state.monuments}
                      pagination={this.state.pagination}
                      style={{
                        backgroundColor: "#fff",
                        margin: "auto"
                      }}
                      onChange={this.handleTableChange}
                    />
                  </React.Fragment>
                )}
              </TabPane>
              <TabPane
                tab={
                  <div>
                    Rejected
                    <Badge
                      showZero
                      style={{ backgroundColor: "#999999" }}
                      count={this.state.pagination.total}
                    />
                  </div>
                }
                key="rejected"
              >
                {this.state.reviews && (
                  <React.Fragment>
                    <Table
                      columns={columnsRejected}
                      dataSource={this.state.reviews}
                      pagination={this.state.pagination}
                      style={{
                        backgroundColor: "#fff",
                        margin: "auto"
                      }}
                      onChange={this.handleTableChange}
                    />
                  </React.Fragment>
                )}
              </TabPane>
            </Tabs>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
