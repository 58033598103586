import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import { FETCH_REVIEWS_BY_MONUMENT, TOGGLE_HIGHLIGHT_FOR_REVIEW } from "./graphql";
import {
  Rate,
  Row,
  Col,
  Select,
  Card,
  Button,
  Skeleton,
  Avatar,
  List,
  Breadcrumb,
  Popconfirm
} from "antd";
import { API_ROOT } from "./util";
import axios from "axios";
import moment from "moment";

export default class ViewReviewByMonU extends Component {
  state = {
    loading: false,
    monumnet_name: "",
    reviews_by_monument: [],
    sortBy: 'highlighted'
  };
  sortByLogic = () => {
    switch (this.state.sortBy) {
      case 'highlighted':
        return {
          is_highlighted: "desc"
        }
        break;
      case 'newest':
        return {
          updated_at: "desc"
        }
        break;
      case 'ratingsH2L':
        return {
          rating: "desc"
        }
        break;
      case 'ratingsL2H':
        return {
          rating: "asc"
        }
        break;

      default:
        return {
          is_highlighted: "desc"
        }
        break;
    }
  }

  fetchReviewByMonument = responseJson => {
    this.setState({ loading: true });
    let query = FETCH_REVIEWS_BY_MONUMENT;
    query.variables = {
      id: this.props.match.params.id,
      order_by: this.sortByLogic()
    };



    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log(res.data);

        this.setState({
          loading: false,
          monumnet_name: res.data.data.monuments_by_pk.name,
          reviews_by_monument: res.data.data.monuments_by_pk.reviews,
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.error(err);
      });
  };
  toggleHighlight = (id, is_highlighted) => {
    this.setState({ loading: true });
    let mutation = TOGGLE_HIGHLIGHT_FOR_REVIEW;
    mutation.variables = {
      id: id,
      is_highlighted: is_highlighted
    };
    

    axios
      .post(API_ROOT, mutation)
      .then(res => {
        console.log("toggleHighlight", res.data);
        // this.setState({ loading: false });
        this.setState({ loading: false }, () => this.fetchReviewByMonument());
      })
      .catch(err => {
        console.error(err);
      });
  };
  onSortChange = (value) => {
    this.setState({ sortBy: value }, ()=> this.fetchReviewByMonument());
  }

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchReviewByMonument();
  }
  render() {
    const { loading, reviews_by_monument: reviews } = this.state;
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            align="bottom"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={4}
              style={{
                textAlign: "left"
              }}
            >
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/reviews">
                      Reviews
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    Monument Reviews
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h3 style={{ margin: '1rem 0 0 0' }}>Monument Reviews</h3>
              </div>
            </Col>
            <Col
              span={8}
              style={{
                textAlign: "right"
              }}
            >
              <span style={{ textDecoration: 'italic' }}>Maximum of two reviews can be highlighted</span>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Card
                title={
                  <div style={{ display: 'flex' }}>
                    <h3>{`Reviews for ${this.state.monumnet_name}`}</h3>
                  </div>
                }
                extra={
                  <React.Fragment>
                    <label style={{ marginRight: '0.5rem' }}>Sort By: </label>
                    <Select
                      defaultValue={this.state.sortBy}
                      style={{ width: 180 }}
                      onChange={this.onSortChange}
                    >
                      <Select.Option value="highlighted">Highlighted First</Select.Option>
                      <Select.Option value="newest">Newest First</Select.Option>
                      <Select.Option value="ratingsH2L">Ratings (High to Low)</Select.Option>
                      <Select.Option value="ratingsL2H">Ratings (Low to High)</Select.Option>
                    </Select>
                  </React.Fragment>
                }
              >
                <Skeleton loading={loading} avatar active>
                  <List
                    dataSource={reviews}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={item.user.avatar ?
                            (
                              <img
                                src={item.user.avatar}
                                alt={item.user.username}
                                height="32px"
                                width="32px"
                                style={{ objectFit: 'cover', borderRadius: '50%' }}
                              />
                            ) : (
                              <Avatar icon="user" />
                          )}
                          title={item.user.username}
                          description={item.comment}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right"
                          }}
                        >
                          <i>
                            Posted on:{" "}
                            {moment(item.created_at).format("Do MMM YYYY")}
                          </i>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row"
                            }}
                          >
                            <Rate disabled defaultValue={item.rating} />
                            <React.Fragment>
                              {item.is_highlighted ? (
                                <Popconfirm
                                  title="This action cannot be undone. Do you wish to proceed?"
                                  onConfirm={() =>
                                    this.toggleHighlight(item.id, false)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    type="link"
                                    style={{ paddingRight: 0 }}
                                  >
                                    Highlighted
                                  </Button>
                                </Popconfirm>
                              ) : (
                                <Button
                                  type="link"
                                  style={{
                                    paddingRight: 0,
                                    color: "#585858",
                                    textDecoration: "underline",
                                    opacity: this.state.reviews_by_monument.filter(monument => monument.is_highlighted).length > 1 ? 0.5 : 1
                                  }}
                                  disabled = {this.state.reviews_by_monument.filter(monument => monument.is_highlighted).length > 1}
                                  onClick={() =>
                                    // this.state.reviews_by_monument.reduce((agg, monument) => {
                                    //   console.log("agg", agg)
                                    //   console.log("is_highlighted", monument.is_highlighted ? 1 : 0)
                                    //   return agg + monument.is_highlighted ? 1 : 0;
                                    // }, 0) > 1 ? console.log("Can't highlight") : this.toggleHighlight(item.id, true)
                                    this.toggleHighlight(item.id, true)
                                    // this.state.reviews_by_monument.filter(monument => monument.is_highlighted).length > 1 ? console.log("can't highlight") : this.toggleHighlight(item.id, true)
                                  }
                                >
                                  Highlight
                                </Button>
                              )}
                            </React.Fragment>
                          </div>
                        </div>
                      </List.Item>
                    )}
                    style={{
                      textAlign: "left"
                    }}
                  />
                </Skeleton>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
