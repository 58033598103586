import React, { Component } from "react";
import Header from "./header";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Icon,
  Popconfirm,
  Divider,
  Breadcrumb,
  Tag
} from "antd";
import { DELETE_MONUMENT, FETCH_MONUMENT_BY_PK } from "./graphql";
import { API_ROOT } from "./util";
import axios from "axios";
import { Link } from "react-router-dom";
import { WrappedEditMonument } from "./editMonumentForm";
import { RenderMicro } from "./renderMicro";
import ViewMonumentGallery from "./viewMonumentGallery";

const { Title, Paragraph, Text } = Typography;

export default class ViewMonument extends Component {
  state = {
    loading: false,
    editMode: false,
    // editMode: true,
    address: "",
    micro_monuments: [],
    monuments_by_pk: {
      details: {
        mt_name: ""
      },
      era: {
        name: ""
      },
      location: {
        coordinates: []
      },
      monuments: [],
      micro_monuments: []
    }
  };

  fetchMonumentByPK = responseJson => {
    let query = FETCH_MONUMENT_BY_PK;
    query.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          monuments_by_pk: res.data.data.monuments_by_pk,
          loading: false
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  onConfirmDeletion = () => {
    let query = DELETE_MONUMENT;
    query.variables = {
      id: Number(this.props.match.params.id)
    };

    axios
      .post(API_ROOT, query)
      .then(res => {
        this.props.history.push("/monuments");
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchMonumentByPK();
  }
  render() {
    const IconText = ({ type, text }) => (
      <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
      </span>
    );
    return (
      <React.Fragment>
        <Header />

        <div
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "1rem" }}
          >
            <Col
              span={6}
              style={{
                textAlign: "left"
              }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/monuments">Monuments</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Monument</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col
              span={8}
              style={{
                textAlign: "right"
              }}
            >
              <Popconfirm
                title="This action cannot be undone. Do you wish to proceed?"
                onConfirm={() => this.onConfirmDeletion()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" style={{ marginRight: "1rem" }}>
                  Delete
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={() =>
                  this.props.history.push(
                    `/monuments/${this.props.match.params.id}/edit`
                  )
                }
              >
                Edit
              </Button>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Card>
                <Row type="flex" justify="center">
                  <Col
                    span={12}
                    style={{
                      textAlign: "left"
                    }}
                  >
                    {this.state.editMode ? (
                      <div>
                        <WrappedEditMonument
                          data={this.state.monuments_by_pk}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <Title level={3}>Monument Details</Title>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Monument Name</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.details.mt_name}
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Location</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.details.mt_location}
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Era</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.era.name}
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Hero image</Text> <br />
                          {this.state.monuments_by_pk.details.hasOwnProperty(
                            "fileListUpload"
                          ) && (
                            <img
                              src={
                                this.state.monuments_by_pk.details
                                  .fileListUpload[0]
                              }
                              width="182px"
                              height="182px"
                              alt="hero_img"
                              style={{ objectFit: "cover" }}
                            />
                          )}
                          {this.state.monuments_by_pk.details.hasOwnProperty(
                            "mt_heroImg"
                          ) && (
                            <React.Fragment>
                              {this.state.monuments_by_pk.details.mt_heroImg ===
                              undefined ? (
                                <img
                                  src={
                                    this.state.monuments_by_pk.details
                                      .fileListUpload[0]
                                  }
                                  width="182px"
                                  height="182px"
                                  alt="hero_img"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <img
                                  src={
                                    this.state.monuments_by_pk.details
                                      .mt_heroImg
                                  }
                                  alt="hero_img"
                                  width="182px"
                                  height="182px"
                                  style={{ objectFit: "cover" }}
                                />
                              )}
                            </React.Fragment>
                          )}
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Video Link</Text>
                          <Paragraph>
                            <a
                              href={this.state.monuments_by_pk.details.mt_video}
                              target="blank"
                            >
                              {this.state.monuments_by_pk.details.mt_video}
                            </a>
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Description</Text>
                          <Paragraph style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.monuments_by_pk.details.mt_description}
                          </Paragraph>
                        </Row>
                        {this.state.monuments_by_pk.details.hasOwnProperty("mt_gallery") && this.state.monuments_by_pk.details.mt_gallery.length !== 0 && (
                          <Row style={{ marginBottom: "1rem" }}>
                            <Text type="secondary">Gallery</Text> <br />
                            <ViewMonumentGallery
                              data={
                                this.state.monuments_by_pk.details.mt_gallery
                              }
                            />
                          </Row>
                        )}
                        {this.state.monuments_by_pk.monuments_x_tags &&
                          this.state.monuments_by_pk.monuments_x_tags.length >
                            0 && (
                            <Row style={{ marginBottom: "1rem" }}>
                              <Text type="secondary">Tag</Text> <br />
                              {this.state.monuments_by_pk.monuments_x_tags.map(
                                tag => (
                                  <Tag>{tag.tag.name}</Tag>
                                )
                              )}
                            </Row>
                          )}
                        <Divider />
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Map Location</Text>
                          <Paragraph>
                            <span style={{ marginRight: "1rem" }}>
                              Latitude
                            </span>
                            {this.state.monuments_by_pk.details.mt_lat
                              ? `${this.state.monuments_by_pk.details.mt_lat}°N`
                              : " - "}
                            <span style={{ margin: "0rem 1rem" }}>
                              Longitude
                            </span>
                            {this.state.monuments_by_pk.details.mt_long
                              ? `${this.state.monuments_by_pk.details.mt_long}°E`
                              : " - "}
                            {this.state.monuments_by_pk.details.mt_lat &&
                            this.state.monuments_by_pk.details.mt_long ? (
                              <a
                                href={`https://www.google.com/maps/search/${this.state.monuments_by_pk.details.mt_lat}%20${this.state.monuments_by_pk.details.mt_long}`}
                                target="_blank"
                                style={{ margin: "0rem 1rem" }}
                              >
                                {" "}
                                Show on map{" "}
                              </a>
                            ) : (
                              <p> None </p>
                            )}
                          </Paragraph>
                        </Row>
                        {/* <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Map</Text>
                          <SimpleMap
                            lat={
                              this.state.monuments_by_pk.location.coordinates[0]
                            }
                            lng={
                              this.state.monuments_by_pk.location.coordinates[1]
                            }
                            onMarker={() => console.log("clicked")}
                            markerVisibility={true}
                          />
                        </Row> */}
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Nearest Airport</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.airport
                              ? this.state.monuments_by_pk.airport.name
                              : null}
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Open Hours</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.details.mt_openFrom !==
                            "Invalid date"
                              ? `${this.state.monuments_by_pk.details.mt_openFrom} h`
                              : " - "}
                            <span style={{ margin: "0rem 1rem" }}>to</span>
                            {this.state.monuments_by_pk.details.mt_openTill !==
                            "Invalid date"
                              ? `${this.state.monuments_by_pk.details.mt_openTill} h`
                              : " - "}
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Additional Text</Text>
                          <Paragraph>
                            {
                              this.state.monuments_by_pk.details
                                .mt_additionalText
                            }
                          </Paragraph>
                        </Row>
                        <Row style={{ marginBottom: "1rem" }}>
                          <Text type="secondary">Contact</Text>
                          <Paragraph>
                            {this.state.monuments_by_pk.details.mt_numberType === "landline" ? `+91 ${this.state.monuments_by_pk.details.mt_phone} ${this.state.monuments_by_pk.details.mt_phoneNumber}` : `+91 ${this.state.monuments_by_pk.details.mt_phoneNumber}`}
                          </Paragraph>
                        </Row>
                      </React.Fragment>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {this.state.monuments_by_pk.micro_monuments.length !== 0 && (
            <Row type="flex" style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <Card>
                <Row type="flex" justify="center">
                  <Col
                    span={12}
                    style={{
                      textAlign: "left"
                    }}
                  >
                    <Title level={3}>Monument Micro Details</Title>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col
                    span={12}
                    style={{
                      textAlign: "left",
                      marginTop: "1rem"
                    }}
                  >
                    <RenderMicro
                      data={this.state.monuments_by_pk.micro_monuments}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          )}
        </div>
      </React.Fragment>
    );
  }
}
