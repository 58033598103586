import React, { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

const ViewMonumentGallery = (props) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	
	const openLightBox = useCallback((index) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);
	
	const closeLightBox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};
	
	const IMAGES = props.data.map((photo, i) => ({ key: `${i}`, src: photo, width: 1, height: 1 }));
	
	return (
		<React.Fragment>
			<div className="position-relative">
				<div style={{ display: "flex", overflow: "scroll" }}>
					{props.data.map((p, i) => {
						return (
							<div onClick={() => openLightBox(i)}>
								<img
									src={p}
									width="182px"
									height="182px"
									alt="hero_img"
									style={{ marginRight: 16, objectFit: 'cover', cursor: 'pointer' }}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightBox}>
						<Carousel
							currentIndex={currentImage}
							views={IMAGES.map(x => ({
								...x,
								srcset: x.srcSet,
								caption: x.title
							}))}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</React.Fragment>
	);
};

export default ViewMonumentGallery;
