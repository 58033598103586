import React, { Component } from "react";

export default class AuthRed extends Component {
  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") === "true") {
      this.props.history.push("/era");
    } else {
      this.props.history.push("/auth");
    }
  }
  render() {
    return <div></div>;
  }
}
