import React from "react";
import {Input, Form, Button} from "antd";
import "../App.css";

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			confirm: "",
		};
	}
	
	handleSubmit = () => {
		this.props.onSubmit(this.state)
	};
	
	onChangeText = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div id="LoginForm" style={{ padding: '2rem 0' }}>
				<h3 style={{
					fontWeight: 600,
					fontSize: 18,
					textAlign: 'center',
					color: '#7E7E7E',
				}}>
					Reset Password
				</h3>
				<Form.Item label="New Password">
					{getFieldDecorator("password", {
						rules: [
							{
								required: true,
								message: "Please enter valid credential"
							}
						],
						initialValue: this.state.password
					})(
						<Input
							name="password"
							onChange={e => this.onChangeText(e)}
							type="password"
							size="large"
						/>
					)}
				</Form.Item>
				<Form.Item label="Confirm Password">
					{getFieldDecorator("confirm", {
						rules: [
							{
								required: true,
								message: "Please enter a valid Password"
							}
						],
						initialValue: this.state.confirm
					})(
						<Input
							name="confirm"
							onChange={e => this.onChangeText(e)}
							type="password"
							size="large"
						/>
					)}
				</Form.Item>
				<Form.Item
					style={{
						marginTop: "1rem"
					}}
				>
					<Button
						disabled={this.state.password.length < 5 || this.state.confirm.length < 5 || (this.state.password !== this.state.confirm)}
						loading={this.state.loading}
						type="primary"
						onClick={this.handleSubmit}
						block
						size="large"
					>
						Reset Password
					</Button>
				</Form.Item>
				{(this.state.password !== this.state.confirm) && (
					<p>Passwords didn't match.</p>
				)}
			</div>
		);
	}
}

export const ResetPasswordForm = Form.create({ name: "ResetPassword" })(ResetPassword);
