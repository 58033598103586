import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link, NavLink } from "react-router-dom";
import { myFirebase } from "./auth/firebase";
import "./App.css";
const SubMenu = Menu.SubMenu;

export default class Header extends Component {
  logout = () => {
    myFirebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        // console.log(this.props);
        window.location.href = "/auth";
        // setTimeout(() => {

        // }, 200);
      })
      .catch(function(error) {
        // An error happened.
      });
  };
  render() {
    if (window.location.pathname === "/auth" || window.location.pathname === "/firebase") {
      return (
        <Menu mode="horizontal" style={{}} theme="dark">
          <Menu.Item
            key="logo"
            style={{
              float: "left",
              fontWeight: 700
            }}
          >
            Monument Universe
          </Menu.Item>
        </Menu>
      );
    } else {
      return (
        <Menu mode="horizontal" style={{}} theme="dark">
          <Menu.Item
            key="logo"
            style={{
              float: "left",
              fontWeight: 700
            }}
          >
            <Link activeClass="active" to="/era">
              Monument Universe
            </Link>
          </Menu.Item>
          <Menu.Item
            key="era"
            style={{
              float: "left"
            }}
          >
            <NavLink activeClass="active" to="/era">
              Era
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="monuments"
            style={{
              float: "left"
            }}
          >
            <NavLink activeClass="active" to="/monuments">
              Monuments
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="reviews"
            style={{
              float: "left"
            }}
          >
            <NavLink activeClass="active" to="/reviews">
              Reviews
            </NavLink>
          </Menu.Item>
          <SubMenu
            key="profile"
            style={{
              float: "right"
            }}
            title={
              <span>
                <Icon type="user" />
              </span>
            }
          >
            {/* <Menu.Item key="clicked" onClick={() => localStorage.clear()}> */}
            <Menu.Item key="clicked" onClick={this.logout}>
              <Link to="/">
                <Icon type="logout" />
                Logout
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      );
    }
  }
}
