import moment from "moment";
import { notification } from "antd";
import axios from "axios";
import AWS from "aws-sdk";

export const requestOptions = {
  method: "POST",
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: "Bearer 7f67f09985436a9393dead69a7c00ee80931d7d1a27dzzee"
    //change this line ^ according to your requirements
  }
};

export const API_ROOT = `${process.env.REACT_APP_API}`;

export const timeStringToMomentDate = timeString => {
  const dt = moment();
  const timeStringArray = timeString.split(":");
  dt.hour(timeStringArray[0]);
  dt.minute(timeStringArray[1]);
  return dt;
};

export const convertUnicode = input => {
  return input.replace(/\\u(\w\w\w\w)/g, function(a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
};

export const openNotification = (type, message) => {
  if (type === "success") {
    notification["success"]({
      message: `Success`,
      description: `${message}`
    });
  } else {
    notification["error"]({
      message: "Something went wrong",
      description: `${message}`
    });
  }
};

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};

export async function signAUrl(fileName, contentType) {
  let spacesEndpoint = new AWS.Endpoint(`sfo2.digitaloceanspaces.com`);
  const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: "RZ53AEZLCKD2ORXICAIU",
    secretAccessKey: "0qVSJ/V6WotHTxnlXlJZWe3+zcerjqwC1VslDEtYYYI",
    region: "sfo2",
    signatureVersion: "v4"
  });
  // const params = JSON.parse(event.body);
  let ran = Math.floor(Math.random() * Math.floor(1000000));
  let newFileName = ran.toString() + fileName.replace(/[^A-Z0-9\.]/gi, "");
  const s3Params = {
    Bucket: "monuments",
    Key: newFileName,
    ContentType: contentType,
    ACL: "public-read"
  };

  const uploadURL = s3.getSignedUrl("putObject", s3Params);
  console.log("uploadURL", uploadURL);
  return uploadURL;
}

export async function getS3UploadUrl(fileName, contentType) {
  const getIt = await axios({
    method: "get",
    url: "https://jovial-ptolemy-be0f94.netlify.com/.netlify/functions/geturl",
    data: {
      key: fileName,
      contentType: contentType
    }
  });
  console.log("getIt", getIt);
  return getIt;
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function(e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          console.log("urlList", urlList);
          xhr.open("put", urlList[index], true);
          // setTimeout(() => {
          //   xhr.setRequestHeader("x-amz-acl", "public-read");
          //   xhr.setRequestHeader("Content-Type", file.type);
          // }, 1000);

          // if (
          //   file.type === "image/png" ||
          //   file.type === "image/jpeg" ||
          //   file.type === "application/pdf"
          // ) {
          //   xhr.setRequestHeader("Content-Type", file.type);

          // } else if (file.type === "audio/wav" || file.type === "audio/webm") {
          //   xhr.setRequestHeader("Content-Type", file.type)
          // } else {
          //   xhr.setRequestHeader("Content-Type", "binary/octet-stream");
          // }

          xhr.setRequestHeader("x-amz-acl", "public-read");
          xhr.setRequestHeader("Content-Type", file.type);
          setTimeout(() => {
            console.log("xhr", xhr.getResponseHeader("Content-Type"));
            console.log("xhr", xhr.getResponseHeader("x-amz-acl"));
            xhr.send(file);
          }, 1000);
        })
    );

    Promise.all(formUploadPromiseArray)
      .then(urls => {
        resolve(urls.map(url => url.split("?")[0]));
      })
      .catch(err => {
        reject(err);
      });
  });
}


export const refreshToken = async () => {
  let response = await fetch(
    `https://securetoken.googleapis.com/v1/token?key=${
      process.env.REACT_APP_FIREBASE_APIKEY
    }&grant_type=refresh_token&refresh_token=${localStorage.getItem(
      "refreshToken"
    )}`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "no-cors", // no-cors, *cors, same-origin
      headers: {
        // "Content-Type": "application/json"
        "Content-Type": "application/x-www-form-urlencoded"
      }
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );
  let data = await response.json();
  // console.log("data", data);
  localStorage.setItem("token", data.id_token);
  localStorage.setItem("refreshToken", data.refresh_token);
  return data;
};
