import React from "react";
import { Icon } from "antd";

let dragingIndex = -1;


const BodyRow = (props) => {
  const {isOver, connectDragSource, connectDropTarget, connectDragPreview, moveRow, ...restProps} = props;
  // const style = {...restProps.style, cursor: 'move'};

  let {className} = restProps;
  if (isOver) {
    if (restProps.index > dragingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < dragingIndex) {
      className += ' drop-over-upward';
    }
  }

  let new_children = props.children.map((child, index) => {
    if (index === props.children.length-1) {
      return connectDragSource(
        <td>
          <span style={{ cursor: "grab" }}>
            <Icon type="menu" />
          </span>
        </td>
      );
    } else {
      return child;
    }
  });

  // console.log(props.children);

  return connectDropTarget(
    connectDragPreview(
      <tr {...restProps} className={className}>
        {new_children}
      </tr>
    )
  )
};

export default BodyRow;
