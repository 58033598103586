import React, { Component } from "react";
import { LoginForm } from "./login";
import { API_ROOT } from "../util";
import { Row, Col } from "antd";

import Header from "../header";
function handleSuper(e) {
  e.preventDefault();
  console.log("Super");
}

export default class Auth extends Component {
  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") === "true") {
      this.props.history.push("/era");
    }
  }
  render() {
    return (
      <div>
        <Header />
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            margin: "2rem 10rem"
          }}
        >
          <Col span={24}>
            {/* <img src={logo } style={{
              width: '10%',
              marginBottom: '2rem',
              marginTop: '4rem'
            }}/> */}
            <LoginForm title="Login" base_url={API_ROOT} {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}
