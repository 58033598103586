// NOTE: for all validations, refer monument-validations.md

import React, { Component } from "react";
import Header from "./header";
import { Prompt } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Empty,
  Drawer,
  Popconfirm,
  Alert,
  Spin
} from "antd";
import { FETCH_ALL_ERA, INSERT_MONUMENTS, INSERT_AIRPORT } from "./graphql";
import { API_ROOT, openNotification } from "./util";
import axios from "axios";
import { WrappedAddMonument } from "./addMonumentForm";
import { WrappedAddMicroMonuForm } from "./addMicroMonuForm";
import moment from "moment";
import { RenderMicro } from "./renderMicro";
const { Title } = Typography;
export default class AddMonument extends Component {
  state = {
    shouldBlockNavigation: false,
    drawerVisible: false,
    loading: false,
    era: [],
    microDetails: [],
    microEditMode: false
  };
  onSaveDraft = values => {
    this.setState({
      loading: false
    });
    const {
      getFieldValue,
      getFieldsValue,
      validateFields,
      setFields
    } = this.formRef.props.form;
    setFields({
      mt_heroImg: {
        value: getFieldValue("mt_heroImg"),
        errors: undefined
      },
      mt_era: {
        value: getFieldValue("mt_era"),
        errors: undefined
      },
      mt_location: {
        value: getFieldValue("mt_location"),
        errors: undefined
      },
      mt_video: {
        value: getFieldValue("mt_video"),
        errors: undefined
      },
      mt_description: {
        value: getFieldValue("mt_description"),
        errors: undefined
      },
      mt_gallery: {
        value: this.formRef.state.fileList4gallery.length
          ? this.formRef.state.fileList4gallery.map(file => file.response)
          : [],
        errors: undefined
      },
      mt_openFrom: {
        value: getFieldValue("mt_openFrom"),
        errors: undefined
      },
      mt_openTill: {
        value: getFieldValue("mt_openTill"),
        errors: undefined
      }
    });
    validateFields(["mt_name", "mt_location", "mt_era"], (err, val) => {
      let fromDraft = true;
      if (!err) {
        console.log(getFieldValue());
        this.insertMonuments(
          getFieldsValue(),
          this.shapeShiftMicro(),
          this.state.microDetails.length > 0,
          fromDraft
        );
      } else {
        openNotification("error", "Please Enter All Required Fields");
        this.setState({ loading: false });
      }
    });
  };

  shapeShiftMicro = () => {
    let microObj = this.state.microDetails.map((dt, index) => {
      return {
        name: dt.name,
        hero_image: dt.hero_image,
        video_url: dt.video_url,
        order: index
      };
    });
    return microObj;
  };

  shapeShiftMonu = vals => {
    let vls = { ...vals };
    vls.mt_heroImg = this.state.fileListUpload;
    vls.mt_openFrom = moment(vls.mt_openFrom).format("HH:mm");
    vls.mt_openTill = moment(vls.mt_openTill).format("HH:mm");
    // vls.mt_heroImg =
    //   vls.mt_heroImg !== undefined ? vls.mt_heroImg[0].thumbUrl : null;
    // vls.mt_openFrom =
    //   vls.mt_openFrom !== undefined
    //     ? moment(vls.mt_openFrom).format("HH:mm a")
    //     : null;
    // vls.mt_openTill =
    //   vls.mt_openTill !== undefined
    //     ? moment(vls.mt_openTill).format("HH:mm a")
    //     : null;
    console.log("shapeShiftMonu", vls);
    return vls;
  };

  onAddMonument = values => {
    console.log(this.formRef);
    const {
      getFieldValue,
      getFieldsValue,
      setFields,
      validateFieldsAndScroll
    } = this.formRef.props.form;

    // console.log("getFieldsValue", getFieldsValue);

    let isActiveOnApp = getFieldValue("mt_isActiveOnApp");
    let fieldsWhenNotActive = [
      "mt_name",
      "mt_location",
      "mt_era",
      "mt_heroImg"
    ];
    setFields({
      mt_gallery: {
        value: this.formRef.state.fileList4gallery.length
          ? this.formRef.state.fileList4gallery.map(file => file.response)
          : [],
        errors: undefined
      },
      mt_heroImg: {
        value:
          this.formRef.state.fileList.length &&
          this.formRef.state.fileList.filter(f => f.response).length
            ? this.formRef.state.fileList.map(file => file.response)
            : [],
        errors: this.formRef.state.fileList.length
          ? undefined
          : "Please upload header img"
      }
    });
    if (isActiveOnApp === "no") {
      validateFieldsAndScroll(fieldsWhenNotActive, (err, val) => {
        if (!err) {
          let fwnA = getFieldsValue();
          console.log(fwnA);
          this.insertMonuments(
            this.shapeShiftMonu(fwnA),
            this.shapeShiftMicro(),
            this.state.microDetails.length > 0,
            false
          );

          console.log("Received form values when inActive: ");
        } else {
          openNotification("error", "Please Enter All Required Fields");
        }
        setFields({
          mt_video: {
            value: getFieldValue("mt_video"),
            errors: undefined
          },
          mt_description: {
            value: getFieldValue("mt_description"),
            errors: undefined
          },
          mt_gallery: {
            value: this.formRef.state.fileList4gallery.length
              ? this.formRef.state.fileList4gallery.map(file => file.response)
              : [],
            errors: undefined
          },
          mt_openFrom: {
            value: getFieldValue("mt_openFrom"),
            errors: undefined
          },
          mt_openTill: {
            value: getFieldValue("mt_openTill"),
            errors: undefined
          }
        });
      });
    } else {
      validateFieldsAndScroll(
        (err, val) => {
          if (!err) {
            let fwnA = getFieldsValue();
            console.log("fwnA", fwnA);

            this.insertMonuments(
              this.shapeShiftMonu(fwnA),
              this.shapeShiftMicro(),
              this.state.microDetails.length > 0,
              false
            );
          } else {
            openNotification("error", "Please Enter All Required Fields");
          }
        },
        { force: true }
      );
    }
  };

  fetchAllEra = responseJson => {
    let query = FETCH_ALL_ERA;
    // let token =  localStorage.getItem('token')

    axios
      .post(API_ROOT, query)
      .then(res => {
        console.log("fetchAllEra", res.data);
        this.setState({
          era: res.data.data.era,
          loading: false,
          selectedEra:
            this.props.location.state !== undefined &&
            this.props.location.state.hasOwnProperty("selectedEra")
              ? this.props.location.state.selectedEra
              : null
        });
      })
      .catch(err => {
        console.error(err);
      });
  };
  handleSubmit = e => {
    e.preventDefault();
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true
    });
  };

  hideDrawer = () => {
    this.setState({
      drawerVisible: false,
      microEditMode: false
    });
  };

  checkIfUploadHappened = () => {
    const { validateFields } = this.formRefMicro.props.form;
    validateFields((e, v) => {
      if (e) {
        console.log(e);
      } else {
        this.onMicroSubmit(v);
      }
    });
  };

  onMicroSubmit = values => {
    console.log(this.formRefMicro);
    if (this.state.microEditMode) {
      console.log("editMode");

      let stateCopy = { ...this.state };
      let newDeet = {
        name: values.name,
        video_url: values.video_url,
        hero_image: this.formRefMicro.state.fileListUpload[0],
        upload: this.formRefMicro.state.fileList
      };
      stateCopy.microDetails.splice(this.state.currentIndexToEdit, 1, newDeet);
      stateCopy.microEditMode = false;
      this.setState(stateCopy);
      this.hideDrawer();
    } else {
      console.log("addMode");
      let stateCopy = { ...this.state };
      let newDeet = {
        name: values.name,
        video_url: values.video_url,
        hero_image: this.formRefMicro.state.fileListUpload[0],
        upload: this.formRefMicro.state.fileList
      };
      stateCopy.microDetails = [...stateCopy.microDetails, newDeet];
      this.setState(stateCopy);
      this.hideDrawer();
    }
    // this.formRefMicro.props.form.validateFields((err, values) => {
    //   if (!err) {
    //     console.log("Received values of form: ", values);
    //     if (this.state.microEditMode) {
    //       console.log("editMode");

    //       let stateCopy = { ...this.state };
    //       let newDeet = {
    //         name: values.name,
    //         video_url: values.video_url,
    //         hero_image: this.formRefMicro.state.fileListUpload[0],
    //         upload: this.formRefMicro.state.fileList
    //       };
    //       stateCopy.microDetails.splice(
    //         this.state.currentIndexToEdit,
    //         1,
    //         newDeet
    //       );
    //       stateCopy.microEditMode = false;
    //       this.setState(stateCopy);
    //       this.hideDrawer();
    //     } else {
    //       console.log("addMode");
    //       let stateCopy = { ...this.state };
    //       let newDeet = {
    //         name: values.name,
    //         video_url: values.video_url,
    //         hero_image: this.formRefMicro.state.fileListUpload[0],
    //         upload: this.formRefMicro.state.fileList
    //       };
    //       stateCopy.microDetails = [...stateCopy.microDetails, newDeet];
    //       this.setState(stateCopy);
    //       this.hideDrawer();
    //     }
    //   }
    // });
  };

  insertMonuments = async (reqObj, microObj, withMicro, fromDraft = false) => {
    // console.log(reqObj, microObj, withMicro, this.formRef.state);
    console.log("reqObj", reqObj);
    console.log(fromDraft);

    // sample micro_monuments
    // {name: "nested 1", hero_image: "nested 1", order: 0, video_url: "nested 1"}
    /// api
    const {
      address,
      lat,
      lng,
      fileList,
      fileList4gallery,
      tag_ids
    } = this.formRef.state;
    let mutation = INSERT_MONUMENTS;
    let fListArr = [];
    fileList.forEach(x => fListArr.push(x.response));
    let fListGArr = [];
    fileList4gallery.forEach(x => fListGArr.push(x.response));

    let airport_data = {
      name: address,
      location: {
        type: "Point",
        coordinates: [lat, lng]
      }
    };

    let airport_mutation = INSERT_AIRPORT;
    airport_mutation.variables = airport_data;

    let airport_id = null;

    // console.log("fileList", fileList);

    await axios
      .post(API_ROOT, airport_mutation)
      .then(res => {
        console.log("insertAirports", res.data);

        airport_id = res.data.data.insert_airports.returning[0].id;

        // this.setState({
        //   loading: false,
        //   shouldBlockNavigation: false
        // });
        // openNotification("success", "Monument has been published");
        // // if (res.data.hasOwnProperty("insert_monuments")) {
        // // }
        // // this.props.history.goBack();
        // this.props.history.push("/monuments");
      })
      .catch(err => {
        console.error(err);
      });

    // mt_formattedAddress: address,
    // mt_mapLocation: address

    let wMicro = {
      objects: {
        era_id: reqObj.mt_era !== undefined ? reqObj.mt_era : null,
        location: {
          type: "Point",
          coordinates: [parseFloat(reqObj.mt_lat), parseFloat(reqObj.mt_long)]
        },

        name: reqObj.mt_name,
        location_name: reqObj.mt_location,
        airport_id: airport_id,
        details: {
          ...reqObj,
          mt_gallery: fileList4gallery.length
            ? this.formRef.state.fileList4gallery.map(file => file.response)
            : [],
          mt_heroImg: fListArr
          // mt_openFrom: moment(reqObj.mt_openFrom).format("HH:mm"),
          // mt_openTill: moment(reqObj.mt_openTill).format("HH:mm")
        },
        micro_monuments: {
          data: microObj
        },
        monuments_x_tags: {
          data: tag_ids.map(tag => ({ tags_id: tag }))
        },
        status: !fromDraft,
        last_updated: moment()
      }
    };
    let draft = {
      objects: {
        era_id: reqObj.mt_era !== undefined ? reqObj.mt_era : null,
        location: {
          type: "Point",
          coordinates: [parseFloat(reqObj.mt_lat), parseFloat(reqObj.mt_long)]
        },
        name: reqObj.mt_name,
        location_name: reqObj.mt_location,
        airport_id: airport_id,
        details: {
          ...reqObj,
          mt_gallery: fileList4gallery.length
            ? this.formRef.state.fileList4gallery.map(file => file.response)
            : [],
          mt_heroImg: fListArr,
          mt_formattedAddress: address,
          mt_mapLocation: address,
          mt_openFrom: moment(reqObj.mt_openFrom).format("HH:mm"),
          mt_openTill: moment(reqObj.mt_openTill).format("HH:mm")
        },
        micro_monuments: {
          data: microObj
        },
        monuments_x_tags: {
          data: tag_ids.map(tag => ({ tags_id: tag }))
        },
        status: !fromDraft,
        last_updated: moment()
      }
    };
    mutation.variables = fromDraft ? draft : wMicro;
    // mutation.variables = withMicro ? wMicro : woMicro;
    // let token =  localStorage.getItem('token')
    // console.log("mutation", mutation);
    console.log("variables", mutation.variables);

    if (mutation.variables.objects.details.mt_gallery.length) {
      let error = false;
      mutation.variables.objects.details.mt_gallery.map(img => {
        if (img === undefined || img === null) {
          error = true;
        }
      });
      if (error) {
        openNotification(
          "error",
          "Gallery upload is in progress. Please try after the upload has been completed"
        );
        return null;
      }
    }

    if (mutation.variables.objects.details.mt_heroImg.length) {
      if (
        mutation.variables.objects.details.mt_heroImg[0] === undefined ||
        mutation.variables.objects.details.mt_heroImg[0] === null
      ) {
        // console.log("UPload failed");
        openNotification(
          "error",
          "Hero image upload is in progress. Please try after the upload has been completed"
        );
        return null;
      }
    }

    if (airport_id) {
      if (!fromDraft) {
        if (reqObj.mt_isActiveOnApp === "no") {
          axios
            .post(API_ROOT, mutation)
            .then(res => {
              console.log("insertMonuments", res.data);

              this.setState({
                loading: false,
                shouldBlockNavigation: false
              });
              openNotification("success", "Monument has been published");
              // if (res.data.hasOwnProperty("insert_monuments")) {
              // }
              // this.props.history.goBack();
              this.props.history.push("/monuments");
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          if (
            this.state.microDetails.length === 0 ||
            this.state.microDetails.length % 2 !== 0
          ) {
            this.setState({ loading: false });
            openNotification(
              "error",
              "Micro details should be uploaded in even numbers"
            );
          } else {
            axios
              .post(API_ROOT, mutation)
              .then(res => {
                console.log("insertMonuments", res.data);

                this.setState({
                  loading: false,
                  shouldBlockNavigation: false
                });
                openNotification("success", "Monument has been published");
                // if (res.data.hasOwnProperty("insert_monuments")) {
                // }
                // this.props.history.goBack();
                this.props.history.push("/monuments");
              })
              .catch(err => {
                console.error(err);
              });
          }
        }
      } else {
        axios
          .post(API_ROOT, mutation)
          .then(res => {
            console.log("insertMonuments", res.data);
            this.setState({
              loading: false,
              shouldBlockNavigation: false
            });
            // if (res.data.hasOwnProperty("insert_monuments")) {

            // this.props.history.goBack();
            this.props.history.push("/monuments");
            openNotification("success", "Monument is saved as Draft");
            // }
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  };

  onConfirmDeletion = params => {
    console.log("on", params);
    let stateCopy = { ...this.state };
    stateCopy.microDetails.splice(this.state.currentIndexToEdit, 1);
    this.setState(stateCopy);
  };

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.props.history.push("/auth");
    }
    this.fetchAllEra();
    this.setState({ shouldBlockNavigation: true });
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Spin tip="Loading..." spinning={this.state.loading}>
          <Header />

          <div
            style={{
              margin: "2rem 10rem"
            }}
          >
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: "1rem" }}
            >
              <Col
                span={8}
                style={{
                  textAlign: "left"
                }}
              >
                Add Monument
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "right"
                }}
              >
                <Button
                  type="danger"
                  style={{
                    marginRight: "1rem"
                  }}
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  type="default"
                  onClick={this.onSaveDraft}
                  style={{
                    marginRight: "1rem"
                  }}
                >
                  Save as draft
                </Button>
                <Button type="primary" onClick={this.onAddMonument}>
                  {/* Add Monument */}
                  Publish
                </Button>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Card>
                  <Row type="flex" justify="center">
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Title level={3}>Monument Details</Title>
                      <WrappedAddMonument
                        selectedEra={this.state.selectedEra}
                        era={this.state.era}
                        onAddMonument={this.onAddMonument}
                        wrappedComponentRef={inst => (this.formRef = inst)}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Row type="flex" justify="center">
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Title level={3}>Monument Micro Details</Title>

                      {this.state.microDetails.length > 0 ? (
                        <React.Fragment>
                          {this.state.microDetails.map((deet, index) => {
                            return (
                              <Card
                                key={index}
                                size="small"
                                title={deet.name}
                                extra={
                                  <React.Fragment>
                                    <Popconfirm
                                      title="This action cannot be undone. Do you wish to proceed?"
                                      onConfirm={this.onConfirmDeletion.bind(
                                        this,
                                        index
                                      )}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a style={{ color: "red" }}>Delete</a>
                                    </Popconfirm>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        this.setState({
                                          microEditMode: true,
                                          drawerVisible: true,
                                          currentItemToEdit: deet,
                                          currentIndexToEdit: index
                                        });
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </React.Fragment>
                                }
                                style={{ marginBottom: "1.25rem" }}
                              >
                                <RenderMicro data={[deet]} index={index} />
                              </Card>
                            );
                          })}
                          {this.state.microDetails.length % 2 !== 0 ? (
                            <Alert
                              message="Please note!"
                              description="Micro details should be uploaded in even numbers"
                              type="warning"
                              showIcon
                            />
                          ) : null}
                          <Button
                            type="default"
                            onClick={this.showDrawer}
                            style={{ marginTop: "1rem" }}
                          >
                            Add New
                          </Button>
                        </React.Fragment>
                      ) : (
                        // TODO: [MU-18] Show even number warning
                        <Empty
                          imageStyle={{
                            height: "11.25rem"
                          }}
                          description={<span>No micro detail</span>}
                        >
                          <Button type="default" onClick={this.showDrawer}>
                            Add New
                          </Button>
                        </Empty>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
          <React.Fragment>
            <Drawer
              destroyOnClose
              width="45%"
              title={
                <div>
                  <Row type="flex" justify="space-between">
                    <Col span={8}>Add New Micro Detail</Col>
                    <Col
                      span={8}
                      style={{
                        textAlign: "right"
                      }}
                    >
                      <Button
                        type="danger"
                        style={{ marginRight: "1rem" }}
                        onClick={this.hideDrawer}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={this.checkIfUploadHappened}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
              placement="right"
              closable={false}
              visible={this.state.drawerVisible}
            >
              <WrappedAddMicroMonuForm
                editMode={this.state.microEditMode}
                initials={this.state.currentItemToEdit}
                onMicroSubmit={this.onMicroSubmit}
                // onMicroSubmit={this.checkIfUploadHappened}
                wrappedComponentRef={inst => (this.formRefMicro = inst)}
              />
            </Drawer>
          </React.Fragment>
        </Spin>
      </React.Fragment>
    );
  }
}
