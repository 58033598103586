import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Auth from "./auth";
import AllEra from "./allEra";
import AddEra from "./addEra";
import ViewEra from "./viewEra";
import AllMonuments from "./allMonuments";
import AddMonument from "./addMonument";
import ViewMonument from "./viewMonument";
import AllReviews from "./allReviews";
import ViewReview from "./viewReview";
import ViewReviewByMonU from "./viewReviewByMonU";
import SimpleMapWrapper from "./gmwrapper";
import AuthRed from "./auth/redirect";
import EditMonument from "./EditMonument";
import axios from "axios";
import { refreshToken } from "./util";
import FirebaseMailVerification from "./FIrebaseMailVerfication";

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    config.headers["X-Hasura-Role"] = `${localStorage.role}`;

    return config;
  },
  function(error) {
    // openNotification("error", `Something went wrong`);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.data.hasOwnProperty("errors")) {
      let noToken =
        response.data.errors[0].extensions.code === "invalid-headers";
      let invalidJwt = response.data.errors[0].extensions.code === "invalid-jwt";
      
      if (noToken || invalidJwt) {
        // localStorage.clear();
        // window.location.href = "/";

        refreshToken();
        return response;
      }
    }

    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default class Main extends Component {
  render() {
    return (
      <main>
        <BrowserRouter>
          <Switch>
            {/* <Redirect exact from="/" to="AuthRed" /> */}
            <Route exact path="/" component={AuthRed} />
            <Route exact path="/auth" component={Auth} />
            <Route exact path="/firebase" component={FirebaseMailVerification} />
            <Route exact path="/era" component={AllEra} />
            <Route exact path="/era/add" component={AddEra} />
            <Route exact path="/era/:id" component={ViewEra} />
            <Route exact path="/monuments" component={AllMonuments} />
            <Route exact path="/monuments/add" component={AddMonument} />
            <Route exact path="/monuments/:id" component={ViewMonument} />
            <Route exact path="/monuments/:id/edit" component={EditMonument} />
            <Route exact path="/reviews" component={AllReviews} />
            <Route exact path="/reviews/:id" component={ViewReview} />
            <Route exact path="/reviews/monument/:id" component={ViewReviewByMonU}/>
            <Route exact path="/map" component={SimpleMapWrapper} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}
