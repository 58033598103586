import AWS from "aws-sdk";

/**
 * Digital Ocean Spaces Connection
 */

const s3 = new AWS.S3({
  endpoint: new AWS.Endpoint("sfo2.digitaloceanspaces.com"),
  accessKeyId: "RZ53AEZLCKD2ORXICAIU",
  secretAccessKey: "0qVSJ/V6WotHTxnlXlJZWe3+zcerjqwC1VslDEtYYYI",
  region: "sfo2",
  signatureVersion: "v4"
});

export default s3;
